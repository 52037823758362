import {
  faInfoCircle,
  faSpinner,
  faTrash,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import { CustomEndData } from "../../../commons/models/models";
import { Context } from "../../../context/auth/Context";
import { types } from "../../../context/types";
import {
  fetchWebauthnCreate,
  fetchWebauthnParseAdd
} from "../../../services/auth";
import { b64decode, b64encode } from "../../../services/common";
import {
  fetchMFAStatus,
  fetchMFAVerify
} from "../../../services/services";
import { InputMFA } from "../../components/InputMFA";
import { MFAForm } from "../../components/MFAForm";
import { MFAFormVerify } from "../../components/MFAFormVerify";
import { SecurityKeyInput } from "../../components/SecurityKeyInput";
import useAPIError from "../../../commons/hooks/useAPIError";

interface ErrorForm {
  verifyCode?: string;
  friendlyDevice?: string;
}

const MFA = ({credKeys, warningWithConfirmMessage}:any) => {
    const history = useHistory();

    // Notify
    const { addNotify } = useAPIError();

    const {
        user: { userDetails: userData },
        dispatch,
    } = useContext(Context);

    const [switchMFA, setSwitchMFA] = useState(false);
    const [friendlyDeviceName, setFriendlyDeviceName] = useState("");
  
    const [showStep, setShowStep] = useState("password");
    const [has2FA, sethas2FA] = useState(false);
    const [TOPTURL, setTOPTURL] = useState("");
    const [TOPTSecret, setTOPTSecret] = useState("");

    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expiresIn = localStorage.getItem("expiresIn");
    const userId = localStorage.getItem("userId");

    const [keyNameAux, setKeyNameAux] = useState('');
    return (
    <>
        <Row>
            <Col lg="12" md="12">
                <Card className="custom-card">
                <CardBody>
                    <CardTitle tag="h3">Authenticator app</CardTitle>
                    <div>
                    <p>
                        Protect your account with an extra Time-based
                        one-time Password (TOTP).
                    </p>
                    </div>
                    <Row>
                    {showStep && showStep === "password" && (
                        <Col lg="4" md="4" sm="6" xs="12">
                        <Formik
                            initialValues={{
                            mfapassword: "",
                            }}
                            validate={(values) => {
                            let errors = {};
                            const error = "This field is required";

                            if (!values.mfapassword) {
                                errors.mfapassword = error;
                            }

                            return errors;
                            }}
                            onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                            ) => {
                            let endData: CustomEndData = {
                                password: values.mfapassword,
                            };

                            const { status, details, message, redirect } =
                                await fetchMFAStatus(endData);

                            if (redirect) {
                                dispatch({
                                type: types.logout,
                                });
                                history.push("/auth/login");
                            } else if (status === "Success") {
                                resetForm();
                                setSwitchMFA(details.enabled);
                                sethas2FA(details.enabled);
                                setShowStep("toggle");

                                if (details.friendlyDeviceName) {
                                setFriendlyDeviceName(
                                    details.friendlyDeviceName
                                );
                                }

                                addNotify("Password is correct", "success");
                            } else {
                                let showMessage =
                                "There was an error while retreiving MFA status.";

                                if (message) {
                                showMessage = message;
                                }

                                addNotify(showMessage, "danger");
                            }

                            setSubmitting(false);
                            }}
                        >
                            {({ isSubmitting }) => (
                            <Form className="form-formik">
                                <InputMFA />
                                <Row>
                                <Col>
                                    <Button
                                    className="btn-fill btn-sm"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    >
                                    {!isSubmitting
                                        ? "Ok"
                                        : "PLEASE WAIT..."}
                                    </Button>
                                </Col>
                                </Row>
                            </Form>
                            )}
                        </Formik>
                        </Col>
                    )}
                    {showStep &&
                        ["toggle", "verify", "checking"].includes(
                        showStep
                        ) && (<>
                        
                            <Col lg="4" md="4" sm="6" xs="12">
                                <MFAForm
                                authVars={{
                                    token,
                                    refreshToken,
                                    expiresIn,
                                    userId: userId,
                                    email: userData.email,
                                }}
                                isSwitched={switchMFA}
                                friendlyDeviceName={friendlyDeviceName}
                                setShowStep={setShowStep}
                                setTOPTURL={setTOPTURL}
                                setTOPTSecret={setTOPTSecret}
                                sethas2FA={sethas2FA}
                                has2FA={has2FA}
                                />
                            </Col>
                        
                        
                            <Col lg="12" md="12" sm="12" xs="12">
                                <Row>
                                    <Col>
                                        <div className="alert alert-warning d-flex">
                                            <div className="d-inline-block justify-content-center align-self-center">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    size="2x"
                                                />
                                            </div>
                                            <div className="d-inline-block justify-content-center align-self-center ml-4">
                                                Your sessions will be terminated on all devices once MFA is activated, including the current one.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                        
                        )}
                    {showStep && "checking" === showStep && (
                        <Col lg="4" md="4" sm="6" xs="12">
                        <div className="text-center">
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                        </Col>
                    )}
                    {!has2FA && showStep && showStep === "verify" && (
                        <Col lg="4" md="4" sm="6" xs="12">
                        <Formik
                            initialValues={{
                            verifyCode: "",
                            friendlyDevice: "",
                            }}
                            validate={(values) => {
                            let errors: ErrorForm = {};
                            const error = "This field is required";

                            if (!values.verifyCode) {
                                errors.verifyCode = error;
                            }

                            if (values.verifyCode.length !== 6) {
                                errors.verifyCode =
                                "The code needs to be 6 digits";
                            }

                            if (
                                values.friendlyDevice.trim().length > 25
                            ) {
                                errors.friendlyDevice =
                                "This name is too long, max 25 characters";
                            }

                            return errors;
                            }}
                            onSubmit={async (
                            values: {
                                verifyCode?: string;
                                friendlyDevice?: string;
                            },
                            { setSubmitting, resetForm }
                            ) => {
                            let verifyData: {
                                userCode: string;
                                friendlyDeviceName?: string;
                            } = {
                                userCode: values.verifyCode
                                ? values.verifyCode
                                : "",
                            };

                            if (
                                values.friendlyDevice &&
                                values.friendlyDevice.trim() !== ""
                            ) {
                                verifyData.friendlyDeviceName =
                                values.friendlyDevice.trim();
                            }

                            const { status, details, message, redirect } =
                                await fetchMFAVerify(verifyData);

                            if (redirect) {
                                dispatch({
                                type: types.logout,
                                });
                                history.push("/auth/login");
                            } else if (status === "Success") {
                                if (details && details.friendlyDeviceName) {
                                setFriendlyDeviceName(
                                    details.friendlyDeviceName
                                );
                                }

                                sethas2FA(true);

                                addNotify(
                                "You have verified your MFA device!",

                                "success"
                                );

                                setShowStep("toggle");

                                resetForm();

                                //Close sessions
                                localStorage.clear();

                                dispatch({
                                    type: types.logout,
                                });
                                history.push("/auth/login");
                            } else {
                                let showMessage =
                                "Incorrect verification code, try again.";

                                if (message) {
                                showMessage = message;
                                }

                                addNotify(showMessage, "danger");
                            }

                            setSubmitting(false);
                            }}
                        >
                            {({ isSubmitting, values, setFieldValue }) => (
                            <Form className="form-formik">
                                <MFAFormVerify
                                TOPTURL={TOPTURL}
                                secret={TOPTSecret}
                                notify={addNotify}
                                values={values}
                                setFieldValue={setFieldValue}
                                />
                                <Row>
                                <Col>
                                    <Button
                                    className="btn-fill btn-sm"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    >
                                    {!isSubmitting
                                        ? "Ok"
                                        : "PLEASE WAIT..."}
                                    </Button>
                                </Col>
                                </Row>
                            </Form>
                            )}
                        </Formik>
                        </Col>
                    )}
                    </Row>

                    
                </CardBody>
                </Card>
            </Col>
        </Row>
            {/*className="d-none" -->*/}
        <Row>
            <Col lg="12" md="12">
                <Card className="custom-card">
                <CardBody>
                    <CardTitle tag="h3">Security keys</CardTitle>
                    <div>
                    <p>
                        Protect your account with a security key (e.g.
                        Yubikey).
                        <br />
                        <small>
                        (the security keys override the authenticator app)
                        </small>
                    </p>
                    </div>
                    <Row>
                        <Col lg="4" md="4" sm="6" xs="12">
                            <Formik
                            initialValues={{
                                keyName: "",
                            }}
                            validate={(values) => {
                                let errors: { keyName?: string } = {};
                                const error = "This field is required";

                                if (!values.keyName) {
                                errors.keyName = error;
                                }

                                setKeyNameAux(values.keyName);

                                if (
                                values.keyName &&
                                values.keyName.length > 30
                                ) {
                                errors.keyName = "Use 30 chars max";
                                }

                                return errors;
                            }}
                            onSubmit={async (
                                values,
                                { setSubmitting, resetForm }
                            ) => {
                                let endData: any = {
                                keyName: values.keyName,
                                };

                                try {
                                const { status, details, message, redirect } =
                                    await fetchWebauthnCreate();

                                if (redirect) {
                                    dispatch({
                                    type: types.logout,
                                    });
                                    history.push("/auth/login");
                                } else if (status === "Success") {
                                    let credOptions = details;
                                    let challenge = credOptions.challenge;
                                    credOptions.user.id = b64decode(
                                    credOptions.user.id
                                    );
                                    credOptions.challenge = b64decode(
                                    credOptions.challenge
                                    );

                                    if (credOptions.excludeCredentials) {
                                    for (
                                        let i = 0,
                                        size =
                                            credOptions.excludeCredentials
                                            .length;
                                        i < size;
                                        i++
                                    ) {
                                        credOptions.excludeCredentials[i].id =
                                        b64decode(
                                            credOptions.excludeCredentials[i].id
                                        );
                                    }
                                    }
                                    console.log("credOptions", credOptions);
                                    //----------create credentials using available authenticator
                                    const cred = await navigator.credentials
                                    .create({
                                        publicKey: credOptions,
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        throw error;
                                    });
                                    //console.log("cred", cred.response.getTransports());
                                    // parse credentials response to extract id and public-key, this is the information needed to register the user in Cognito
                                    const credential: {
                                    id?: any;
                                    rawId?: any;
                                    type?: any;
                                    challenge?: any;
                                    response?: any;
                                    transports?: any;
                                    } = {};
                                    credential.id =
                                    cred && cred.id ? cred.id : null;
                                    credential.rawId = b64encode(
                                    cred && cred.rawId ? cred.rawId : null
                                    );
                                    credential.type =
                                    cred && cred.type ? cred.type : null;
                                    credential.challenge = challenge;

                                    if (cred && cred.response) {
                                    const clientDataJSON = b64encode(
                                        cred.response.clientDataJSON
                                    );
                                    const attestationObject = b64encode(
                                        cred.response.attestationObject
                                    );
                                    credential.response = {
                                        clientDataJSON,
                                        attestationObject,
                                    };

                                    credential.transports = JSON.stringify(
                                        cred.response.getTransports()
                                    );
                                    }
                                    endData = { ...endData, ...credential };

                                    const parseResult =
                                    await fetchWebauthnParseAdd(endData);

                                    const { status, redirect } = parseResult;

                                    if (redirect) {
                                    dispatch({
                                        type: types.logout,
                                    });
                                    history.push("/auth/login");
                                    } else if (status === "Success") {
                                        resetForm();
                                        addNotify(
                                            "Security key added!",
                                            "success"
                                        );

                                        //Close sessions
                                        localStorage.clear();

                                        dispatch({
                                            type: types.logout,
                                        });
                                        history.push("/auth/login");

                                        /*let tempCreds = Object.assign(
                                            {},
                                            credKeys
                                        );

                                        tempCreds[parseResult.details.credId] = {
                                            name: parseResult.details.name,
                                        };

                                        let tempDetails: any = {};
                                        tempDetails["publicKeysCred"] = tempCreds;

                                        dispatch2({
                                            type: dataTypes.profile,
                                            payload: {
                                            ...userDetails,
                                            ...tempDetails,
                                            profileTabName: pageTabs,
                                            },
                                        });

                                        setIsCredKeys(tempCreds);*/
                                    } else {
                                    let message =
                                        "There was an error adding your security key.";

                                    if (res.message) {
                                        message = res.message;
                                    }

                                    addNotify(message, "danger");
                                    }
                                } else {
                                    let message =
                                    "There was an error creating the credentials.";

                                    if (res.message) {
                                    message = res.message;
                                    }

                                    addNotify(message, "danger");
                                }

                                setSubmitting(false);
                                } catch (error: any) {
                                console.log(error);
                                addNotify(error.message, "danger");
                                }
                            }}
                            >
                            {({ isSubmitting }) => (
                                <Form className="form-formik">
                                <SecurityKeyInput />
                                <Row>
                                    <Col>
                                    <Button
                                        className="btn-fill btn-sm"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {!isSubmitting
                                        ? "Add key"
                                        : "PLEASE WAIT..."}
                                    </Button>
                                    {!isSubmitting ? (
                                        <></>
                                    ) : (
                                        <div className="mt-1">
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="spinner"
                                        ></FontAwesomeIcon>{" "}
                                        This operation may take few seconds...
                                        </div>
                                    )}
                                    </Col>
                                </Row>
                                </Form>
                            )}
                            </Formik>
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="12">
                            <Label>Your keys</Label>

                            {credKeys && Object.keys(credKeys).length === 1 && (
                            <>
                                <FontAwesomeIcon
                                color="#f63b3b"
                                icon={faInfoCircle}
                                className="left5"
                                id="your-keys-icon"
                                />
                                <UncontrolledTooltip
                                delay={0}
                                target="your-keys-icon"
                                placement="right"
                                >
                                It is recommended to have a secondary security
                                key just in case the primary gets lost, stolen
                                or unavailable.
                                </UncontrolledTooltip>
                            </>
                            )}
                            <div className="box-keys">
                            {credKeys && (
                                <>
                                {Object.keys(credKeys).map((credId) => (
                                    <div
                                    className="flex-start key-row"
                                    key={credId}
                                    >
                                    <p className="credential-name-mfa">
                                        {credKeys[credId]["name"]}
                                    </p>
                                    <div className="credential-name-mfa-delete">
                                        <FontAwesomeIcon
                                        className="cursor-p"
                                        icon={faTrash}
                                        color="#ff4757"
                                        onClick={() => {
                                            warningWithConfirmMessage(
                                            credId,
                                            credKeys[credId]["name"]
                                            );
                                        }}
                                        />
                                    </div>
                                    </div>
                                ))}
                                </>
                            )}

                            {(!credKeys ||
                                (credKeys &&
                                Object.keys(credKeys).length == 0)) && (
                                <div className="text-center mt-1 alert alert-info">
                                <span>
                                    <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="left5"
                                    />{" "}
                                    You don't have any security key added
                                </span>
                                </div>
                            )}
                            </div>
                        </Col>

                        {keyNameAux && <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                            <Row>
                                <Col>
                                    <div className="alert alert-warning d-flex">
                                        <div className="d-inline-block justify-content-center align-self-center">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                size="2x"
                                            />
                                        </div>
                                        <div className="d-inline-block justify-content-center align-self-center ml-4">
                                            Your sessions will be terminated on all devices once a new security key is added, including the current one.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>}
                    </Row>
                    
                </CardBody>
                </Card>
            </Col>
        </Row>
    </>
    );
};

export default MFA;