import { useEffect, useReducer, useState } from "react";
import Joyride from "react-joyride";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import APIErrorProvider from "./commons/providers/APIErrorProvider";
import { APIErrorNotification } from "./components/APIErrorNotification/APIErrorNotification";
import { Context } from "./context/auth/Context";
import { appReducer } from "./context/auth/reducer";
import { DataContext } from "./context/data/DataContext";
import { dataReducer } from "./context/data/dataReducer";
import Admin from "./layouts/Admin/Admin";
import AdminPortal from "./layouts/AdminPortal/AdminPortal";
import Auth from "./layouts/Auth/Auth";
import { PrivateRoute } from "./layouts/PrivateRoute";
import { PublicRoute } from "./layouts/PublicRoute";

const init = () => {
  let user: any = localStorage.getItem("user");
  if (user && JSON.parse(user).userDetails) {
    return JSON.parse(user);
  } else {
    return {
      logged: false,
    };
  }
};

export const App = () => {
  const [user, dispatch] = useReducer(appReducer, {}, init);
  const [profile, dispatch2] = useReducer(dataReducer, {});
  const [appTour, setAppTour] = useState([
    {
      content: (
        <>
          <h2>Get started!</h2>
          <p>Lets go through a quick how to use guide.</p>
        </>
      ),
      //locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: "center",
      target: "body",
    },
    {
      target: ".btn-sharepass",
      content: (
        <>
          <h2>Send or receive secrets</h2>
          <p>Use this button to start creating new secrets or envelopes.</p>
        </>
      ),
      placement: "right",
    },
    {
      target: ".btn-purpose",
      content: (
        <>
          <h2>Change mode</h2>
          <p>
            Click the icon to switch accordignly to send or receive mode, in
            case you want to create and envelope or a secret.
          </p>
        </>
      ),
    },
  ]);

  if(window.location.pathname.includes("/auth/") && !document.body.classList.contains("white-content")){
    document.body.classList.add("white-content");
  } else {
    if (
      (user.lightTheme && !document.body.classList.contains("white-content")) ||
      (!user.lightTheme && document.body.classList.contains("white-content"))
    ) {
      document.body.classList.toggle("white-content");
    }
  } 
  

  if (
    (user.sidebarToggle && document.body.classList.contains("sidebar-mini")) ||
    (!user.sidebarToggle && !document.body.classList.contains("sidebar-mini"))
  ) {
    document.body.classList.toggle("sidebar-mini");
  }

  useEffect(() => {
    /**
     * This snippet is to keep the isMobile flag alive
     */
    let isMobile = false;

    /**
     * If the login page has been opened from the mobile app we need to add a localstorage flag.
     */
    if (window.location.search.includes("isMobile")) {
      localStorage.setItem("isMobile", "true");
    }

    // Firefox case when token is expired
    if (!user.logged) {
      if (localStorage.getItem("isMobile")) {
        isMobile = true;
      }

      localStorage.clear();
    }

    if (isMobile) {
      localStorage.setItem("isMobile", "true");
    }

    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const handleJoyrideCallback = (e: any) => {
    console.log("handleJoyrideCallback", e);
  };

  return (
    <Context.Provider value={{ user, dispatch }}>
      <DataContext.Provider value={{ profile, dispatch2 }}>
        <APIErrorProvider>
          <APIErrorNotification />
          <BrowserRouter>
            <Switch>
              <PublicRoute
                path="/auth"
                component={(props: any) => <Auth {...props} />}
                isAuthenticated={user.logged}
              />
              <PrivateRoute
                path="/admin"
                component={(props: any) => <Admin {...props} />}
                isAuthenticated={user.logged}
              />
              <PrivateRoute
                path="/adminportal"
                component={(props: any) => <AdminPortal {...props} />}
                isAuthenticated={user.logged}
              />
              <Redirect from="/" to="/admin/dashboard" />
            </Switch>
          </BrowserRouter>
          <Joyride
            steps={appTour}
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            scrollToFirstStep
            showProgress
            showSkipButton
            run={false}
            styles={{
              options: {
                arrowColor: "#e3ffeb",
                //backgroundColor: '#e3ffeb',
                //overlayColor: 'rgba(79, 26, 0, 0.4)',
                primaryColor: "#000",
                textColor: "#004a14",
              },
            }}
          />
        </APIErrorProvider>
      </DataContext.Provider>
    </Context.Provider>
  );
};
