/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Redirect, Route, Switch } from "react-router-dom";
import FrontendConfig from "../../assets/js/config";
import Footer from "../../components/Footer/Footer.js";
import routes from "../../routes.js";
import { MobileEnvelope } from "../../views/mobile/MobileEnvelope";
import EnrollSecurityKey from "../../views/pages/EnrollSecurityKey";
import EnvelopeComplete from "../../views/pages/EnvelopeComplete";
import Login from "../../views/pages/Login.js";
import SSO from "../../views/pages/SSO";

const PagesDefault = (props: any) => {
  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  }, []);

  const isMobile = localStorage.getItem("isMobile");

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        if (prop.path === "/login") {
          return null;
        }

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes: any) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getFullPageName = (routes: any) => {
    let pageName = getActiveRoute(routes);
    switch (pageName) {
      case "Pricing":
        return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Complete your envelope":
        return "envelope-page";
      case "Enroll security key":
        return "enroll-security-key-page";
      case "Forgot Password":
        return "forgot-page";
      case "Resend Link":
        return "resend-page";
      default:
        return "Default Brand Text";
    }
  };

  return (
    <>
      <div
        className={`wrapper wrapper-full-page ${
          isMobile ? "white-content" : "wrapper-login"
        }`}
      >
        <div
          className={
            isMobile
              ? "full-page-mobile"
              : "full-page wrapper-login " + getFullPageName(routes)
          }
        >
          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/sso" component={SSO} />
            <Route
              path="/auth/enroll-security-key"
              component={EnrollSecurityKey}
            />
            <Route path="/auth/envelope/:id" component={EnvelopeComplete} />
            <Route path="/auth/mobile-envelope" component={MobileEnvelope} />
            <GoogleReCaptchaProvider reCaptchaKey={FrontendConfig.reCaptchaKey}>
              {getRoutes(routes)}
            </GoogleReCaptchaProvider>
            <Redirect from="*" to="/auth/login" />
          </Switch>
          {!isMobile && <Footer fluid />}
        </div>
      </div>
    </>
  );
};

/**
 * Environment override file if exists
 */
const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/layouts/Auth/Auth");
  } catch (err) {
    return null;
  }
};

const Pages = tryRequire() ? tryRequire().default : PagesDefault;

export default Pages;
