import { RJSFSchema, UiSchema, RegistryWidgetsType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';
import IpInputWidget from './rjsfWidgets/IpInputWidget';
import IpFullInputWidget from './rjsfWidgets/IpFullInputWidget';

export const InputJSONSchema = ({ customSecretSchemas, schemaId, customSecretSchemaValue, setCustomSecretSchemaValue } : any) => {

  function transformErrors(errors: any, uiSchema: any) {
    console.log("All errors: ", errors);
    return errors.map((error: any) => {
      if (error.name === 'pattern') {
        error.message = 'Only digits are allowed';
      }
      return error;
    });
  }

  /*console.log("customSecretSchemas",customSecretSchemas);
  console.log("schemaId",schemaId);*/

  let schema:RJSFSchema = {};
  let uiSchema: UiSchema = {};

  customSecretSchemas.forEach((item:any) => {
    if(item.id == schemaId){
      schema = item.details.main;
      uiSchema = item.details.uiSchema;
    }
  });

  /**
   * Add default values if exists to form recursively in case of nested form elements
   */
  function traverseJSON(jsonObject: any, values: any) {
    if (typeof jsonObject === 'object') {
      for (var key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
          if(values[key]){
            jsonObject[key]['default'] = values[key];
          }
          traverseJSON(jsonObject[key], values);
        }
      }
    }
  }

  traverseJSON(schema, customSecretSchemaValue);

  /**
   * Include customized widgets if required, based on property format value
   */
  function traverseFormat(jsonObject: any) {
    if (typeof jsonObject === 'object') {
      for (var key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
          if(jsonObject[key]['ui:widget']){
            
            switch(jsonObject[key]['ui:widget']){
              case "IpInputWidget":
                jsonObject[key]['ui:widget'] = IpInputWidget;
              break;
              case "IpFullInputWidget":
                jsonObject[key]['ui:widget'] = IpFullInputWidget;
              break;
            }
          }
          traverseFormat(jsonObject[key]);
        }
      }
    }
  }

  traverseFormat(uiSchema);
  
  const onChangeHandler = (event: any, option: any = null) => {
    //console.log('onChangeHandler', event.formData, option);
    setCustomSecretSchemaValue(event.formData);
  };
  
  const onSubmitHandler = (event: any, option: any = null) => {
    console.log('onSubmitHandler', event, option);
  };
  
  const onErrorHandler = (event: any, option: any = null) => {
    console.log('onErrorHandler', event, option);
  };


  function MoveUpButton(props:any) {
    return (<></>);
  }

  function MoveDownButton(props:any) {
    return (<></>);
  }
  
  return (<Form
      schema={schema}
      validator={validator}
      transformErrors={transformErrors}
      onChange={onChangeHandler}
      onSubmit={onSubmitHandler}
      onError={onErrorHandler}
      uiSchema={uiSchema}
      templates={{ ButtonTemplates: { MoveUpButton, MoveDownButton } }}
      tagName="div"
  />);
};
