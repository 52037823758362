import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faCheckCircle,
  faCircleXmark,
  faCode,
  faComment,
  faKey,
  faPencil,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, UncontrolledTooltip } from "reactstrap";

interface Params {
  title: string;
  value: string;
  hasIcon?: boolean;
  status?: string;
}

interface SecretType {
  icon: any;
  iconName: string;
}

export const SecretDetailsItem = ({
  title,
  value,
  hasIcon,
  status = "",
}: Params) => {
  let secretType: SecretType = {
    icon: "",
    iconName: "",
  };

  if (title === "Type") {
    switch (value) {
      case "password":
        secretType.icon = faKey;
        secretType.iconName = "Password";
        break;
      case "message":
        secretType.icon = faComment;
        secretType.iconName = "Message";
        break;
      case "credentials":
        secretType.icon = faAddressCard;
        secretType.iconName = "Credentials";
        break;
      case "custom":
        secretType.icon = faCode;
        secretType.iconName = "JSON";
        break;
      case "QR":
        secretType.icon = faQrcode;
        secretType.iconName = "QR";
        break;
      case "file":
        secretType.icon = faFile;
        secretType.iconName = "File";
        break;
      case "schema":
        secretType.icon = faPencil;
        secretType.iconName = "Custom schema";
        break;
      default:
        break;
    }
  } else if (title === "Opened" || title === "Erased") {
    if (value.toString() === "true") {
      secretType.icon = faCheckCircle;
    } else {
      secretType.icon = faCircleXmark;
    }
  }

  return (
    <h5>
      <div className="flex">
        <Col md={4} className="right">
          <b>{title}</b>
        </Col>
        {!hasIcon && (
          <Col md={8} className="left">
            {title === "Type" ? (
              <>
                <FontAwesomeIcon id={`type-${value}`} icon={secretType.icon} />
                <UncontrolledTooltip
                  delay={0}
                  target={`type-${value}`}
                  placement="right"
                >
                  {secretType.iconName}
                </UncontrolledTooltip>
              </>
            ) : (
              value
            )}
          </Col>
        )}
        {hasIcon && (
          <div className="info-list">
            <FontAwesomeIcon
              id="PinTooltip"
              icon={secretType.icon}
              color={value ? "#00bf9a" : "#ccc"}
            />
          </div>
        )}
      </div>
    </h5>
  );
};
