import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import FrontendConfig from "../../assets/js/config";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import { fetchLoginSSO } from "../../services/auth";
import {
  challenge_from_verifier,
  generateRandomString,
} from "../../services/common";
import { fetchUserProfile } from "../../services/services";

const Redirect = () => {
  const history = useHistory();
  const location = useLocation();

  const { dispatch }: any = useContext(Context);

  // Context for profile
  const { dispatch2 } = useContext(DataContext);

  const [alert, setAlert] = useState(<></>);
  const [showAlert, setShowAlert] = useState(false);

  const handleSSO = async (idp: string) => {
    const idpURL = encodeURIComponent(idp);
    const redirect = encodeURIComponent(FrontendConfig.sso.redirect);
    const cid = encodeURIComponent(FrontendConfig.sso.cid);
    const type = encodeURIComponent(FrontendConfig.sso.type);
    const scope = encodeURIComponent(FrontendConfig.sso.scope);
    const state = encodeURIComponent(generateRandomString());
    const verifier = generateRandomString();
    const code_challenge = encodeURIComponent(
      await challenge_from_verifier(verifier)
    );

    localStorage.setItem("verifier", verifier);

    window.location.assign(
      `${FrontendConfig.auth_url}/oauth2/authorize?identity_provider=${idpURL}&redirect_uri=${redirect}&response_type=${type}&client_id=${cid}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
    );
  };

  useEffect(
    () => {
        const warningWithConfirmMessage = (title: string, message: string) => {
          setShowAlert(true);
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={title}
              onConfirm={() => {
                history.push("/auth/login");
              }}
              confirmBtnBsStyle="primary"
              confirmBtnText="Try again"
            >
              {message}
            </ReactBSAlert>
          );
        };

      const checkRedirect = async () => {
        const urlHandler = new URLSearchParams(location.search);
        const idp = urlHandler.get("idp");

        if (idp) {
          
            handleSSO(idp);
            
        } else {
          
            warningWithConfirmMessage(
                "Error",
                "Invalid request, please try again."
            );
        }
      };

      checkRedirect();
    },
    [
      /* dispatch, dispatch2, history, location.search */
    ]
  );

  //redirect-positioning

  return (
    <>
      {showAlert && alert}
      <div className="content">
        <div className="row">
          <Col className="text-center" lg="12" md="12" sm="12" xs="12">
            <h3>PLEASE WAIT ...</h3>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Redirect;
