import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { CustomEndData } from "../../../commons/models/models";
import {
  fetchChangePassword
} from "../../../services/services";
import { InputChangePassword } from "../../components/InputChangePassword";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import useAPIError from "../../../commons/hooks/useAPIError";
import { Context } from "../../../context/auth/Context";
import { types } from "../../../context/types";

const Password = () => {

    const history = useHistory();

    // Notify
    const { addNotify } = useAPIError();

    const {
        dispatch,
    } = useContext(Context);

    return (
        <Row>
            <Col lg="12" md="12">
                <Card className="custom-card">
                    <CardBody>
                        <CardTitle tag="h3">Password</CardTitle>
                        <div>
                            <p>
                                Choose a strong password and don't reuse it for
                                other accounts. We recommend you to change your
                                password every 6 months
                            </p>
                        </div>
                        <Row>
                            <Col lg="4" md="4" sm="6" xs="12">
                                <Formik
                                    initialValues={{
                                        password: "",
                                        newPassword: "",
                                        confirmNewPassword: "",
                                    }}
                                    validate={(values) => {
                                        let errors = {};
                                        const error = "This field is required";

                                        if (!values.password) {
                                            errors.password = error;
                                        }

                                        if (!values.newPassword) {
                                            errors.newPassword = error;
                                        }

                                        if (!values.confirmNewPassword) {
                                            errors.confirmNewPassword = error;
                                        }

                                        if (values.confirmNewPassword.length < 8) {
                                            errors.confirmNewPassword =
                                                "Passwords need at least 8 characters.";
                                        }

                                        if (
                                            values.confirmNewPassword !==
                                            values.newPassword
                                        ) {
                                            errors.confirmNewPassword =
                                                "Password confirmation doesn't match.";
                                        }

                                        return errors;
                                    }}
                                    onSubmit={async (
                                        values,
                                        { setSubmitting, resetForm }
                                    ) => {
                                        let endData: CustomEndData = {
                                            previousPassword: values.password,
                                            proposedPassword: values.newPassword,
                                        };

                                        const { status, redirect, message } =
                                            await fetchChangePassword(endData);

                                        if (redirect) {
                                            dispatch({
                                                type: types.logout,
                                            });
                                            history.push("/auth/login");
                                        } else if (status === "Success") {
                                            resetForm();
                                            addNotify(
                                                "Password updated successfully",
                                                "success"
                                            );

                                            //Close sessions
                                            localStorage.clear();

                                            dispatch({
                                                type: types.logout,
                                            });
                                            history.push("/auth/login");
                                        } else {
                                            let showMessage =
                                                "There was an error while updating the details";

                                            if (message) {
                                                showMessage = message;
                                            }

                                            addNotify(showMessage, "danger");
                                        }
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ isSubmitting, values }) => (
                                        <Form className="form-formik">
                                            <InputChangePassword />

                                            <Row className="mb-2">
                                                <Col>
                                                    <Button
                                                        className="btn-fill btn-sm"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {!isSubmitting
                                                            ? "Ok"
                                                            : "PLEASE WAIT..."}
                                                    </Button>
                                                </Col>
                                            </Row>

                                            {(values.newPassword || values.confirmNewPassword) && <Row>
                                                <Col>
                                                    <div className="alert alert-warning d-flex">
                                                        <div className="d-inline-block justify-content-center align-self-center">
                                                            <FontAwesomeIcon
                                                                icon={faExclamationTriangle}
                                                                size="2x"
                                                            />
                                                        </div>
                                                        <div className="d-inline-block justify-content-center align-self-center ml-4">
                                                            Your sessions will be terminated on all devices once your password is updated, including the current one.
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>}
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Password;