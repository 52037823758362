import "./footer.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/img/sharepass-white-logo.svg";

// const notify = () => toast("News Letter Send SuccessFully.");
const Footer = () => {

    

    return (
        <>
            <section className="footer-auth">
                <div className="content_sec">
                    <div className="link_wrap">
                        <div className="logo_wrap">
                            <img src={logo} alt="logo" />
                        </div>
                    </div>   

                    <p className="copyright">Copyright © {new Date().getFullYear()} SharePass Pty Ltd (ACN 647 015 601) (SharePass). SharePass is a dedicated solution for confidential data sharing. Our mission is to make your online presence more secure and confidential. All rights reserved.</p>
                    <div>
                        <div className="bottom_line">

                            <a href="https://sharepass.com/terms-of-service">Terms of Service</a>
                            <a href="https://sharepass.com/subscription-terms">Subscription Terms</a>
                            <a href="https://sharepass.com/permitted-use-policy">Permitted use policy</a>
                            <a href="https://sharepass.com/privacy-policy">Privacy Policy</a>
                            <a href="https://sharepass.com/cookies-policy">Cookies Policy</a>
                            <a href="https://sharepass.com/faq">FAQ</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;