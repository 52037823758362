import InstanceConfig from "../assets/js/instanceConfig";

let optionsAux = [
  {
    value: "",
    label: "None",
    isDisabled: false,
  },
  { value: "Static", label: "Static pin" },
  { value: "Dynamic", label: "Dynamic pin" },
];

if (InstanceConfig().emailAuth) {
  optionsAux.push({ value: "Email", label: "Email" });
}
if (InstanceConfig().securityKeyAuth) {
  optionsAux.push({ value: "Security key", label: "Security key" });
}

export const options = optionsAux;
