import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
} from "reactstrap";
import FrontendConfig from "../../assets/js/config";

interface InfoProps {
  goToSubscribe: any;
  goToManage: any;
  subscription: string;
  endSubscription: string;
  renewSubscription: string;
  secrestLeft: string;
  bytesLeft: string;
  secretTotalUsedPercent: string;
  dataTotalUsedPercent: string;
  stars: number;
  trial: boolean;
  enabled: boolean;
}

export const WidgetSubscription = ({
  goToSubscribe,
  subscription,
  endSubscription,
  renewSubscription,
  secrestLeft,
  bytesLeft,
  secretTotalUsedPercent,
  dataTotalUsedPercent,
  stars,
  trial,
  enabled,
}: InfoProps) => {
  const [dataClass, setDataClass] = useState("");
  const [dateClass, setDateClass] = useState("");
  const [subtitle, setSubtitle] = useState({ name: "", class: "" });
  const [dateSubscriptionEvent, setDateSubscriptionEvent] = useState("");
  const [secretsClass, setSecretsClass] = useState("");
  const [secretPercentageUsage, setSecretPercentageUsage] = useState("");
  const [bytesClass, setBytesClass] = useState("");
  const [dataPercentageUsage, setDataPercentageUsage] = useState("");
  const [isWebApp, setIsWebApp] = useState(true);
  useEffect(() => {
    let dataClassAux;
    let dateClassAux;
    let dateSubscriptionEventAux;
    let subTitleAux = { name: "", class: "" };

    let bytesClassAux = "info";

    if (bytesLeft <= "0") {
      bytesClassAux = "danger";
    }

    let secretsClassAux = "info";

    if (secrestLeft <= "0") {
      secretsClassAux = "danger";
    }

    let secretPercentageUsageAux = "";

    if (secretTotalUsedPercent) {
      if (parseInt(secretTotalUsedPercent) >= 100) {
        secretPercentageUsageAux = "100";
      }

      if (parseInt(secretTotalUsedPercent) <= 0) {
        secretPercentageUsageAux = "0";
      }

      secretPercentageUsageAux =
        secretPercentageUsageAux === "100" || secretPercentageUsageAux === "0"
          ? secretPercentageUsageAux
          : secretTotalUsedPercent;

      secretPercentageUsageAux = `(${secretPercentageUsageAux}% used)`;
    }

    let dataPercentageUsageAux = "";
    if (dataTotalUsedPercent) {
      if (parseInt(dataTotalUsedPercent) >= 100) {
        dataPercentageUsageAux = "100";
      }

      if (parseInt(dataTotalUsedPercent) <= 0) {
        dataPercentageUsageAux = "0";
      }

      dataPercentageUsageAux =
        dataPercentageUsageAux === "100" || dataPercentageUsageAux === "0"
          ? dataPercentageUsageAux
          : dataTotalUsedPercent;

      dataPercentageUsageAux = `(${dataPercentageUsageAux}% used)`;
    }

    if (enabled) {
      if (trial) {
        subTitleAux.name = "(trial)";
        dateSubscriptionEventAux = renewSubscription
          ? "Trial ends on " + renewSubscription
          : "Subscription will be cancelled on " + endSubscription;
        dateClassAux = renewSubscription ? "info" : "danger";
        dataClassAux = renewSubscription ? "info" : "info";
      } else {
        dateSubscriptionEventAux = renewSubscription
          ? "Subscription will be renewed on " + renewSubscription
          : "Subscription will be cancelled on " + endSubscription;
        dateClassAux = renewSubscription ? "info" : "danger";
        dataClassAux = renewSubscription ? "info" : "danger";
      }
    } else {
      subTitleAux.name = "cancelled";
      subTitleAux.class = "text-danger";
      dateSubscriptionEventAux = "Subscription cancelled on " + endSubscription;
      dateClassAux = "danger";
      dataClassAux = "danger";
    }

    dateClassAux = "plan " + dateClassAux;
    dataClassAux = "plan";
    setSubtitle({ name: subTitleAux.name, class: subTitleAux.class });
    setDataClass(dataClassAux);
    setDateClass(dateClassAux);
    setDateSubscriptionEvent(dateSubscriptionEventAux);
    setSecretsClass(secretsClassAux);
    setSecretPercentageUsage(secretPercentageUsageAux);
    setBytesClass(bytesClassAux);
    setDataPercentageUsage(dataPercentageUsageAux);
  }, [
    subscription,
    bytesLeft,
    dataTotalUsedPercent,
    enabled,
    endSubscription,
    renewSubscription,
    secrestLeft,
    secretTotalUsedPercent,
    trial,
  ]);

  /*useEffect(() => {
    setIsWebApp(
      window.location.hostname.length <= 25 
    );
  }, []);*/

  if (subscription) {
    return (
      <Col lg="4" md="6">
        <Card className="card-stats">
          <CardBody>
            <CardTitle className="info-plan text-center" tag="h4">
              <FontAwesomeIcon
                id="PinTooltip"
                icon={faCreditCard}
                color="#fff"
              />{" "}
              Subscription
            </CardTitle>
            <div className={`custom-card-plan ${dataClass}`}>
              <div>
                <Label>Plan</Label>
                <br />
                <Rating
                  iconsCount={4}
                  initialValue={stars}
                  size={12}
                  readonly
                  emptyColor="gray"
                />
                <p className="card-description">
                  {subscription}
                  <small className={subtitle.class}> {subtitle.name}</small>
                </p>
                <div className={`date ${dateClass}`}>
                  <p className="card-description">{dateSubscriptionEvent}</p>
                </div>
              </div>
              <div className={`top5 ${secretsClass}`}>
                <Label>Secrets left </Label>
                <p className="card-description">
                  {secrestLeft < "0" ? "0" : secrestLeft}{" "}
                  {secretPercentageUsage}
                </p>
              </div>
              <div className={`top5 ${bytesClass}`}>
                <Label>Bytes left</Label>
                <p>
                  {bytesLeft < "0" ? "0" : bytesLeft} {dataPercentageUsage}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  } else {
    return (
      <Col lg="4" md="6">
        <Card className="card-stats">
          <CardBody>
            <CardTitle className="info-plan" tag="h1">
              <Alert className="alert-with-icon" color="primary" fade={false}>
                <span data-notify="icon" className="tim-icons icon-bell-55" />
                <span data-notify="message" className="subscribe-notification">
                  <div>
                    <strong>No information available yet.</strong>
                  </div>
                </span>
              </Alert>
            </CardTitle>
            <div className="text-center">
              {isWebApp ? (
                <Button color="primary" onClick={goToSubscribe}>
                  Check our plans
                </Button>
              ) : (
                <a
                  className="btn btn-primary"
                  href={`${FrontendConfig.current_url}/admin/settings/plans`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Check our plans
                </a>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
};
