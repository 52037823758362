import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  CardHeader,
  Collapse,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import useAPIError from "../../../commons/hooks/useAPIError";
import { Context } from "../../../context/auth/Context";
import { types } from "../../../context/types";
import { fetchAdminUpdateTemplate, fetchAdminLabels } from "../../../services/adminServices";
import { parseClassLabels } from "../../../utils/settings";
import { parseConfig, templateRequest } from "../../../utils/templates";
import { CustomErrorField } from "../../components/CustomErrorField";
import { CustomModalLabel } from "../../components/CustomModalLabel";
import { SecretSettings } from "../../components/SecretSettings";

export const EditTemplate = () => {
  /* Handle state */

  const history = useHistory();

  // Get name from context
  const { user: { purposeMode }, dispatch } = useContext(Context);

  // Notify
  const { addNotify } = useAPIError();

  /* Store initial values to the form */
  const [initialFormValues, setInitialFormValues] = useState({
    name: "",
    private: "",
    public: "",
  });

  // To keep the original status
  const [starred, setStarred] = useState(false);

  // To save qty2 and load from a template
  const [qty2Aux, setQtyAux] = useState(0);

  const [config, setConfig] = useState({
    lockedByOwner: false,
    data: null,
    error: false,
    availability: {
      isSwitched: false,
      isSwitched2: false,
      dateTimeEnd: undefined,
      dateTimeStart: undefined,
      opt: "Right now",
      opt2: "Days",
      qty: undefined,
      qty2: 7,
    },
    authorization: { opt: "No pin", pin: 0 },
  });

  const [template, setTemplate] = useState(null);
  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.template
    ) {
      setTemplate(history.location.state.template);
    }
  }, [history]);

  useEffect(() => {
    if (template && initialFormValues.name === "") {
      const { name, details, starred } = template;

      // Initial values
      // Name, public and private description
      setInitialFormValues({
        name: name,
        private: details.privateDescription ? details.privateDescription : "",
        public: details.publicDescription ? details.publicDescription : "",
      });

      let configAux: any = {
        // Visibility (1)
        ips: details.ipList || [],
      };

      // Availability (2)
      const { availability } = parseConfig(details);

      if (availability.qty2) {
        setQtyAux(availability.qty2);
      }
      configAux.availability = availability;

      // Accesibility (3)
      configAux.lockedByOwner = details.lockedByOwner;

      if (details.label && details.label.codeName && details.label.codeName) {
        setmultipleSelect(parseClassLabels([details.label]));
      }

      // Authorization (4)
      let auhtorizationAux: any = {};
      if (details.pin && details.pin.static) {
        auhtorizationAux.opt = "Static";
        auhtorizationAux.pin = details.pin.static;
      } else if (details.pin && details.pin.dynamic) {
        auhtorizationAux.opt = "Dynamic";
        auhtorizationAux.pin = details.pin.dynamic;
      }else if(details.keys && details.keys.length > 0){
        auhtorizationAux.opt = "Security key";
        auhtorizationAux.keys = [];
        for(let i = 0, size = details.keys.length; i < size; i++){
          
          auhtorizationAux.keys.push(details.keys[i]); 

          auhtorizationAux.keys[i].labelAux = details.keys[i].label;
          auhtorizationAux.keys[i].valueAux = details.keys[i].value;
          auhtorizationAux.keys[i].label = details.keys[i].name;
          auhtorizationAux.keys[i].value = details.keys[i].id;
        }
        
      } else if (details.emailVerification && details.emailVerification.id) {
        auhtorizationAux.opt = "Email";
        auhtorizationAux.email = details.emailVerification.id;
        auhtorizationAux.hint = details.emailVerification.hint;
      }

      if (auhtorizationAux.pin || auhtorizationAux.keys || auhtorizationAux.email) {
        configAux.authorization = auhtorizationAux;
      }

      setConfig((prevConfig) => ({
        ...prevConfig,
        ...configAux,
        update: true,
      }));

      // Set starred value
      setStarred(starred);
    }
  }, [template, initialFormValues]);

  const goToTemplateList = () => {
    history.push("/adminportal/template/list");
  };

  const [multipleSelect, setmultipleSelect] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  /* Show label modal */
  const [modalClassic, setModalClassic] = useState(false);

  /*useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        if (details.length > 0) {
          const auxLabels = parseClassLabels(details);
          setLabelOptions([
            {
              label: "Select an option",
              value: "Select an option",
              isDisabled: true,
            },
            ...auxLabels,
          ]);
        }
      }
    };

    if (labelOptions.length === 0) getLabels();
  }, [labelOptions]);*/
  /*
   * We use this condition to put only one label, if we want to add more labels
   * in the future, we will need to delete this condition.
   */
  useEffect(() => {
    if (multipleSelect.length > 1) {
      setmultipleSelect((prevValues) => [prevValues[1]]);
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      label: {
        name: multipleSelect && multipleSelect[0] && multipleSelect[0].label,
        color: multipleSelect && multipleSelect[0] && multipleSelect[0].color,
      },
    }));
  }, [multipleSelect]);

  const addNewLabel = () => {
    setModalClassic(!modalClassic);
  };

  /*
   * Listen close modal
   * After close modal (maybe succes or cancelled by the user) we need to update the array of labels
   * In the future, we will remove, and by replaced by hooks of redux or context.
   */
  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchAdminLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxLabels = parseClassLabels(details);
        setLabelOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxLabels,
        ]);
      }
    };

    if (!modalClassic) getLabels();
  }, [modalClassic]);

  const [openedCollapseSettings, setOpenedCollapseSettings] = useState(true);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card md="6">
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialFormValues}
                  validate={(values) => {
                    let errors: any = {};
                    const error = "This field is required";

                    if (!values.name) {
                      errors.name = error;
                    } else {
                      if (!/^[a-zA-Z0-9-_ ()]{1,30}$/.test(values.name))
                        errors.name = "Please, enter a valid name";
                    }

                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const bodyAux = templateRequest(config);

                    let body: any = {
                      name: values.name,
                      updateAttributes: {
                        starred,
                        details: {
                          ...bodyAux,
                        },
                      },
                    };

                    if (values.private)
                      body.updateAttributes.details.privateDescription =
                        values.private;

                    if (values.public)
                      body.updateAttributes.details.publicDescription =
                        values.public;

                    if(template){
                      body.id = template.id;
                    }
                    console.log("BODY: ", body);
                    const { status, redirect } = await fetchAdminUpdateTemplate(
                      body,
                      purposeMode
                    );

                    if (redirect) {
                      dispatch({
                        type: types.logout,
                      });
                      history.push("/auth/login");
                    } else if (status === "Success") {
                      setSubmitting(false);

                      addNotify(
                        "The template was successfully updated",
                        "success"
                      );
                    } else {
                      addNotify(
                        "An error has ocurred when trying to update the template",
                        "danger"
                      );
                    }

                    // After edit template, we redirect to template list
                    history.push("/adminportal/template/list");
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="form-formik">
                      <Row>
                        <Col md={6}>
                          <Label>Template name</Label>
                          <FormGroup>
                            <Field type="text" name="name" disabled />
                          </FormGroup>
                          <ErrorMessage
                            name="name"
                            component={CustomErrorField}
                          />
                        </Col>
                        <Col md={6}>
                          <div className="box-label">
                            {labelOptions.length <= 1 ? (
                              <Label>
                                You don't have a label yet.{" "}
                                <b className={"cursor-p"} onClick={addNewLabel}>
                                  Create one
                                </b>
                              </Label>
                            ) : (
                              <div>
                                <Label className="">Label</Label>
                                <FontAwesomeIcon
                                  icon={faCog}
                                  className="left5 cursor-p"
                                  onClick={addNewLabel}
                                />
                              </div>
                            )}
                          </div>
                          <CustomModalLabel
                            isOpen={modalClassic}
                            admin={true}
                            toggle={addNewLabel}
                          />
                          <Select
                            className={`mb-2 react-select ${
                              multipleSelect[0]
                                ? multipleSelect[0].color
                                : "mb-2 info"
                            }`}
                            classNamePrefix="react-select"
                            placeholder="Choose label"
                            name="multipleSelect"
                            closeMenuOnSelect
                            isMulti
                            value={multipleSelect}
                            onChange={(value) => setmultipleSelect(value)}
                            options={labelOptions}
                            isDisabled={labelOptions.length <= 1}
                          />

                          <Label>
                            Private description
                            <FontAwesomeIcon
                              id="pDescription"
                              icon={faInfoCircle}
                              color="#0d6efd"
                              width="40px"
                              className="left4"
                            />
                            <UncontrolledTooltip
                              delay={0}
                              target="pDescription"
                              placement="right"
                            >
                              This description is private for your own use to be
                              able to identify secrets easily.
                            </UncontrolledTooltip>
                          </Label>
                          <FormGroup>
                            <Field type="text" name="private" />
                          </FormGroup>
                          <ErrorMessage
                            name="private"
                            component={CustomErrorField}
                          />
                          <Label>Public description</Label>
                          <FontAwesomeIcon
                            id="publicDescription"
                            icon={faInfoCircle}
                            color="#0d6efd"
                            width="40px"
                            className="left4"
                          />
                          <UncontrolledTooltip
                            delay={0}
                            target="publicDescription"
                            placement="right"
                          >
                            This description will be shown to those users that
                            access the link, describe here the purpose or the
                            content of the secret being shared.
                          </UncontrolledTooltip>
                          <FormGroup>
                            <Field type="text" name="public" />
                          </FormGroup>
                          <ErrorMessage
                            name="public"
                            component={CustomErrorField}
                          />
                        </Col>
                      </Row>

                      <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                      >
                        <Card className="card-plain">
                          <CardHeader role="tab">
                            <a
                              aria-expanded={openedCollapseSettings}
                              href="#pabo"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              onClick={(e) => {
                                e.preventDefault();                                
                                setOpenedCollapseSettings(!openedCollapseSettings); 
                              }}
                            >
                              Security settings
                              <i className="tim-icons icon-minimal-down" />
                            </a>
                          </CardHeader>
                          <Collapse
                            role="tabpanel"
                            isOpen={openedCollapseSettings}
                            className="collapse-settings"
                          >
                            <CardBody>
                              <Card className="card-plain card-subcategories">
                                <CardBody>
                                  <SecretSettings 
                                    admin={true}
                                    setConfig={setConfig}
                                    config={config}
                                    qty2Aux={qty2Aux}
                                  />
                                </CardBody>
                              </Card>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                      <hr />
                      <Row className="center-sp">
                        <Button
                          className="btn-fill"
                          color="default"
                          onClick={goToTemplateList}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-fill"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || config.error}
                        >
                          Save
                        </Button>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
