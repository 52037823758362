
import "./header.css";
import logo from '../../../../assets/img/sharepassnewlogo.svg';
import whiteLogo from '../../../../assets/img/sharepass-white-logo.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, Fragment } from "react";

function Header() {

    let arrow = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.34131 0.6875C7.52881 0.520833 7.70589 0.520833 7.87256 0.6875L14.4038 7.25C14.5913 7.41667 14.5913 7.58333 14.4038 7.75L7.87256 14.3125C7.70589 14.4792 7.52881 14.4792 7.34131 14.3125L6.71631 13.6875C6.65381 13.625 6.62256 13.5417 6.62256 13.4375C6.62256 13.3333 6.65381 13.2396 6.71631 13.1562L11.5601 8.3125H0.903809C0.653809 8.3125 0.528809 8.1875 0.528809 7.9375V7.0625C0.528809 6.8125 0.653809 6.6875 0.903809 6.6875H11.5601L6.71631 1.84375C6.54964 1.65625 6.54964 1.47917 6.71631 1.3125L7.34131 0.6875Z" fill="#02F3A2"></path></svg>

    const [status, setStatus] = useState(false);

    const menuToggler = () => {
        setStatus(!status);
    };

    const closeMenu = () => {
        setStatus(false);
    };
    const location = useLocation();

    const currentPath = location.pathname;
    return (
        <Fragment>
            <div className={`header ${status ? 'dark' : ''}`}>
                <div className="header_content">
                    <div className="logo_wrap">
                        <a href="https://sharepass.com" 
                        target="_blank"
                        activeClassName="active"
                        rel="noopener noreferrer">
                            <img src={status ? whiteLogo : logo} alt="logo" />
                        </a>
                    </div>
                    <div className="nav_wrap">
                        <a href="https://sharepass.com" ><button type='button'>Home</button></a>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default Header;