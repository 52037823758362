import moment from "moment";
import { Col } from "reactstrap";
import { parseSecret } from "../../utils/secret";
import { SecretDetailsInput } from "./SecretDetailsInput";
import { SecretDetailsItem } from "./SecretDetailsItem";

export const CustomItemProfileModal = ({
  secretDetail,
  setSecretId,
  isLoading,
  updateSecret,
}: any) => {
  let secretDetailParsed = parseSecret([secretDetail]);

  secretDetail = secretDetailParsed[0];

  return (
    <div className="secret-detail">
      <hr />

      {secretDetail && secretDetail.creation && (
        <div className="secret-box-details">
          <SecretDetailsItem
            title="Id"
            value={secretDetail.secretId}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Type"
            value={secretDetail.type}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Creation date"
            value={moment(secretDetail.creation * 1000).format(
              "DD/MM/YY HH:mm:ss"
            )}
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Modified date"
            value={
              secretDetail.creation !== secretDetail.lastModified
                ? moment(secretDetail.lastModified * 1000).format(
                    "DD/MM/YY HH:mm:ss"
                  )
                : "Never modified"
            }
            hasIcon={false}
          />
          <SecretDetailsItem
            title="Opened"
            value={secretDetail.opened}
            hasIcon={true}
          />
          <SecretDetailsItem
            title="Erased"
            value={secretDetail.secretErased}
            hasIcon={true}
          />
          <SecretDetailsInput
            title="Private description"
            value={secretDetail.privateDescription}
            updateSecret={updateSecret}
            secret={secretDetail}
          />
          <SecretDetailsInput
            title="Public description"
            value={secretDetail.publicDescription}
            updateSecret={updateSecret}
            secret={secretDetail}
          />

          <h5>
            <div className="flex react-table">
              <Col md={4} className="right">
                <b>Lock/Unlock</b>
              </Col>
              <Col md={8} className="text-center">
                <div
                  id={`switch-${secretDetail.secretId})`}
                  onClick={() => {
                    if (
                      !isLoading &&
                      secretDetail.statusAux !== "Expired" &&
                      secretDetail.statusAux !== "Erased"
                    ) {
                      secretDetail.lockedByOwner = !secretDetail.lockedByOwner;
                      let secretDetailParsed = parseSecret([secretDetail]);

                      secretDetail = secretDetailParsed[0];
                      setSecretId(secretDetail.secretId);
                    }
                  }}
                  checked={secretDetail.lockedByOwner}
                  disabled={
                    isLoading ||
                    secretDetail.statusAux === "Expired" ||
                    secretDetail.statusAux === "Erased"
                  }
                  className={`${
                    isLoading ||
                    secretDetail.statusAux === "Expired" ||
                    secretDetail.statusAux === "Erased"
                      ? "btn-lock-unlock disabled"
                      : "btn-lock-unlock"
                  }`}
                >
                  {secretDetail.lockedAux}
                </div>
              </Col>
            </div>
          </h5>
        </div>
      )}
    </div>
  );
};
