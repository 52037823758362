/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Redirect, Route, Switch } from "react-router-dom";
import FrontendConfig from "../../../../assets/js/config";
//import Footer from "../../../../components/Footer/Footer.js";
import routes from "../../../../routes.js";
import Login from "../../../../views/pages/Login";
import SSO from "../../../../views/pages/SSO";
import EnrollSecurityKey from "../../../../views/pages/EnrollSecurityKey";
import Header from "../../../../layouts/Auth/components/Header/Header";
import Footer from "../../../../layouts/Auth/components/footer/Footer";
import EnvelopeComplete from "../../../../views/pages/EnvelopeComplete";
import { MobileEnvelope } from "../../../../views/mobile/MobileEnvelope";

const Pages = (props: any) => {
  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  }, []);

  const isMobile = localStorage.getItem("isMobile") || window.location.search.includes("isMobile");

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        if (prop.path === "/login") {
          return null;
        }

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes: any) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute: any = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getFullPageName = (routes: any) => {
    let pageName = getActiveRoute(routes);
    switch (pageName) {
      case "Pricing":
        return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Complete your envelope":
        return "envelope-page";
      case "Enroll security key":
        return "enroll-security-key-page";
      case "Forgot Password":
        return "forgot-page";
      case "Resend Link":
        return "resend-page";
      default:
        return "Default Brand Text";
    }
  };

  return (
    <>
    <div className="alert alert-warning alert-banner">Staging website</div>
      {!isMobile && <Header />}
      <div
        className={/*`wrapper wrapper-full-page ${
          isMobile ? "white-content" : "wrapper-login"
        }`*/''}
      >
        <div
          className={''/*
            isMobile
              ? "full-page-mobile"
              : "full-page wrapper-login " + getFullPageName(routes)
      */}
        >
          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/sso" component={SSO} />
            <Route
              path="/auth/enroll-security-key"
              component={EnrollSecurityKey}
            />
            <Route path="/auth/envelope/:id" component={EnvelopeComplete} />
            <Route path="/auth/mobile-envelope" component={MobileEnvelope} />
            <GoogleReCaptchaProvider reCaptchaKey={FrontendConfig.reCaptchaKey}>
              {getRoutes(routes)}
            </GoogleReCaptchaProvider>
            <Redirect from="*" to="/auth/login" />
          </Switch>
          {!isMobile && <Footer  />}
        </div>
      </div>
    </>
  );
};

export default Pages;
