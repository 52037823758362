import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  CustomInput,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import arrow from "../../assets/img/arrow-clockwise.svg";
import clipboardCheckW from "../../assets/img/clipboard-check-white.svg";
import clipboardCheck from "../../assets/img/clipboard-check.svg";
import clipboard from "../../assets/img/clipboard.svg";
import lock from "../../assets/img/lock.svg";
import unlock from "../../assets/img/unlock.svg";
import { options } from "../../constants/inputAuthorization";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchAdminSecretCredentials } from "../../services/adminServices";
import { fetchSecretCredentials } from "../../services/settings";
import { validateEmail } from "../../utils";
import { generateRandomSecret2FA } from "../../utils/random";
import { parsePublicKeys } from "../../utils/settings";

interface Params {
  admin?: boolean;
  config: any;
  setConfig: any;
}

export const InputAuthorization = ({ admin, config, setConfig }: Params) => {
  const history = useHistory();

  const { dispatch } = useContext(Context);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [copy, setCopy] = useState(false);

  const [pin, setPin] = useState(
    config.authorization &&
      config.authorization.opt === "Dynamic" &&
      config.authorization.pin
      ? config.authorization.pin
      : generateRandomSecret2FA()
  );

  const [email, setEmail] = useState(
    config.authorization &&
      config.authorization.opt === "Email" &&
      config.authorization.email
      ? config.authorization.email
      : ""
  );

  const [emailHint, setEmailHint] = useState(
    config.authorization &&
      config.authorization.opt === "Email" &&
      config.authorization.hint
      ? true
      : false
  );

  /* Show label modal */

  const [keySelect, setkeySelect] = useState(
    config.authorization &&
      config.authorization.opt === "Security key" &&
      config.authorization &&
      config.authorization.keys
      ? config.authorization.keys
      : []
  );
  const [keyOptions, setkeyOptions] = useState([]);

  const [simplePin, setSimplePin] = useState(
    config.authorization &&
      config.authorization.opt === "Static" &&
      config.authorization &&
      config.authorization.pin
      ? config.authorization.pin
      : "123456"
  );
  const [isLock, setIsLock] = useState(true);

  const generateRandonPin = () => {
    const randonValue = generateRandomSecret2FA();
    setPin(randonValue);

    // To update dynamic pin each time.
    // If we don't do that, will send the last dynamic pin, not the current value.
    setConfig((prevConfig: any) => ({
      ...prevConfig,
      authorization: { opt: "Dynamic", pin: randonValue },
    }));
  };

  const setCopied = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1200);
  };
  const [errorDynamicPin, setErrorDynamicPin] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorSecurityKey, setErrorSecurityKey] = useState(false);
  const handlePin = (value: any) => {
    setSimplePin(value);
    if (value.length === 6) {
      setErrorPin(false);
      setConfig((prevConfig) => ({
        ...prevConfig,
        error: false,
        errorAuthorization: false,
      }));
    } else {
      setErrorPin(true);
      setConfig((prevConfig) => ({
        ...prevConfig,
        error: true,
        errorAuthorization: true,
      }));
    }
  };

  const handleEmail = (value: any) => {
    setEmail(value);
    let errorEmail = validateEmail(value);
    setErrorEmail(errorEmail);
    setConfig((prevConfig) => ({
      ...prevConfig,
      error: errorEmail,
      errorAuthorization: errorEmail,
    }));
  };

  const handleEmailHint = () => {
    if (emailHint) setEmailHint(false);
    setEmailHint(!emailHint);
  };

  const addNewKey = () => {
    if (admin) {
      history.push("/adminportal/security-key/list");
    } else {
      history.push("/admin/security-key/new-security-key");
    }
  };

  useEffect(() => {
    // Setup de default value after load a template for static pin
    if (config.authorization && config.authorization.opt === "Static")
      setSimplePin(config.authorization.pin);

    /*
     * When template doesn't have a authorization config, try to fill with dynamic pin as default.
     * So, here we go back, and set no pin config.
     */

    if (
      config.authorization &&
      config.authorization.opt === "Dynamic" &&
      pin === undefined
    ) {
      setSelectedOption({
        value: "",
        label: "None",
        isDisabled: false,
      });
    }

    if (selectedOption.value === "Dynamic" && typeof pin !== "undefined") {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Dynamic", pin: pin ? pin : 0 },
      }));
    } else if (
      selectedOption.value === "Static" &&
      typeof simplePin !== "undefined"
    ) {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Static", pin: simplePin ? simplePin : 0 },
      }));
    } else if (
      selectedOption.value === "Security key" &&
      keySelect.length > 0
    ) {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Security key", keys: keySelect },
      }));
    } else if (selectedOption.value === "Email" && keySelect.length > 0) {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Email", email: email, hint: emailHint },
      }));
    } else {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: undefined,
      }));
    }
  }, [selectedOption, setConfig]);

  /*
   * To update config after update the pin, so when we create
   * a new secret, we send the updated pin.
   */
  useEffect(() => {
    if (selectedOption.value === "Static") {
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Static", pin: simplePin ? simplePin : 0 },
        error: simplePin.length < 6,
        errorAuthorization: simplePin.length < 6,
      }));
    } else if (selectedOption.value === "Dynamic") {
      /* We need to update error pin */
      setErrorDynamicPin(pin.length !== 32);
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Dynamic", pin: pin },
        error: pin.length !== 32,
        errorAuthorization: pin.length !== 32,
      }));
    } else if (selectedOption.value === "Security key") {
      if (keySelect.length > 3) {
        setkeySelect((prevValues: any) => [
          prevValues[1],
          prevValues[2],
          prevValues[3],
        ]);
      }

      /* We need to update error pin */
      setErrorSecurityKey(keySelect.length === 0);
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Security key", keys: keySelect },
        error: keySelect.length === 0,
        errorAuthorization: keySelect.length === 0,
      }));
    } else if (selectedOption.value === "Email") {
      let errorEmail = validateEmail(email);
      /* We need to update error pin */
      setErrorEmail(errorEmail);
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        authorization: { opt: "Email", email: email, hint: emailHint },
        error: errorEmail,
        errorAuthorization: errorEmail,
      }));
    } else {
      /* We need to update error pin */
      setConfig((prevConfig: any) => ({
        ...prevConfig,
        error: false,
        errorAuthorization: false,
      }));
    }
  }, [simplePin, pin, selectedOption, keySelect, email, emailHint, setConfig]);

  /*
   * Whe need to refresh pin codes because we can't initialice when render the first time Check what's happen here..
   */
  useEffect(() => {
    const { authorization } = config;

    if (authorization && config.update) {
      const { opt, pin, keys, email, hint } = authorization;

      if (opt === "Static") {
        if (opt !== selectedOption.value) {
          setSelectedOption({ value: opt, label: opt });
        } else {
          setSimplePin(pin);
        }
      } else if (opt === "Dynamic" && opt !== selectedOption.value) {
        setSelectedOption({ value: opt, label: opt });
        setPin(pin);
      } else if (
        authorization.opt === "Dynamic" &&
        (authorization.pin === 0 || authorization.pin === undefined)
      ) {
        setSelectedOption({ value: "", label: "None", isDisabled: false });
      } else if (authorization.opt === "Dynamic") {
        setSelectedOption({ value: opt, label: opt });
        setPin(pin);
      } else if (authorization.opt === "Security key") {
        if (opt !== selectedOption.value) {
          setSelectedOption({ value: opt, label: opt });
        }
        setkeySelect(keys);
      } else if (authorization.opt === "Email") {
        if (opt !== selectedOption.value) {
          setSelectedOption({ value: opt, label: opt });
        }
        setEmail(email);
        setEmailHint(hint);
      }
    } else if (config.update) {
      setSelectedOption({ value: "", label: "None", isDisabled: false });
    }
  }, [config]);

  useEffect(() => {
    const getKeys = async () => {
      const { status, details, redirect } = admin
        ? await fetchAdminSecretCredentials()
        : await fetchSecretCredentials(true);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxKeys = parsePublicKeys(details);
        setkeyOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxKeys,
        ]);
      }
    };

    if (keyOptions.length == 0) getKeys();
  }, [keyOptions]);

  /*useEffect(() => {
    
    if (keySelect.length > 3) {
      setkeySelect((prevValues) => [prevValues[1], prevValues[2], prevValues[3]]);
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      authorization: { opt: "Security key", keys: keySelect },
    }));
  }, [keySelect]);*/

  return (
    <div className="fadeInDown">
      <Row className="mt-2">
        <Col md="3"></Col>
        <Col md="6">
          <div className="form-group">
            <Label>Type</Label>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              value={selectedOption}
              onChange={setSelectedOption}
              options={options}
              placeholder="Single Select"
            />
          </div>
          {selectedOption.value === "Static" && (
            <div className="form-group">
              <Label className="font14 m0 inline-f">
                Pin
                <FontAwesomeIcon
                  id="PinTooltip"
                  className="left4"
                  icon={faInfoCircle}
                  color="#0d6efd"
                />
              </Label>
              <UncontrolledTooltip delay={0} target="PinTooltip">
                This PIN needs to be shared with the secret receiver, as it will
                be required for secret revelation.
              </UncontrolledTooltip>
              <Input
                type="number"
                value={simplePin}
                onChange={(e) =>
                  e.target.value.length <= 6 && handlePin(e.target.value)
                }
              />
              {errorPin && <p className="error">Pin must have 6 characters</p>}
            </div>
          )}
          {selectedOption.value === "Dynamic" && (
            <div className="form-group">
              <p className="font14 m0 inline-f">Secret</p>
              <div className="flex">
                <Input
                  type="text"
                  disabled={isLock}
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                />

                <img
                  alt="lock-unlock icon"
                  src={isLock ? lock : unlock}
                  onClick={() => setIsLock(!isLock)}
                  className="btn-lock"
                />
                <img
                  alt="copy-icon"
                  className={`btn-lock ${copy ? "" : ""}`}
                  onClick={() => {
                    setCopied();
                    navigator.clipboard.writeText(pin);
                  }}
                  src={copy ? clipboardCheck : clipboard}
                />
              </div>

              <Button
                className="btn btn-sm btn-random"
                color="primary"
                onClick={generateRandonPin}
              >
                <img alt="random-icon" src={arrow} width="20" /> Random
              </Button>
              {errorDynamicPin && (
                <div>
                  <b className="error">Secret must have 32 characters</b>
                </div>
              )}
              {copy && (
                <Button
                  className="btn btn-sm btn-random cursor-np marginR0"
                  color="primary"
                  disabled
                >
                  <img alt="copied-icon" src={clipboardCheckW} width="20" />{" "}
                  Copied!
                </Button>
              )}
            </div>
          )}
          {selectedOption.value === "Email" && (
            <div className="form-group">
              <Label className="font14 m0 inline-f">
                Email
                <FontAwesomeIcon
                  id="EmailTooltip"
                  className="left4"
                  icon={faInfoCircle}
                  color="#0d6efd"
                />
              </Label>
              <UncontrolledTooltip delay={0} target="EmailTooltip">
                The user will need to verify this email in order to open the
                link.
              </UncontrolledTooltip>
              <Input
                type="email"
                value={email}
                onChange={(e) =>
                  e.target.value.length <= 110 && handleEmail(e.target.value)
                }
              />
              {errorEmail && (
                <p className="error">Must enter a valid email address</p>
              )}

              <div className="top10">
                <Label>
                  Hint
                  <FontAwesomeIcon
                    id="EmailHintTooltip"
                    className="left4"
                    icon={faInfoCircle}
                    color="#0d6efd"
                  />
                </Label>
                <UncontrolledTooltip delay={0} target="EmailHintTooltip">
                  Enable this option to partially reveal the email address to
                  the user receiving the secret.
                  <br />
                  Example: 'john@example.com' will be revealed as
                  'jo**@e******.c**'
                </UncontrolledTooltip>
                <CustomInput
                  type="switch"
                  id="emailHintSwitch"
                  onChange={handleEmailHint}
                  checked={emailHint}
                />
              </div>
            </div>
          )}
          {selectedOption.value === "Security key" && (
            <div className="form-group">
              <div className="box-label">
                {keyOptions.length <= 1 ? (
                  <Label>
                    You don't have a security key yet.{" "}
                    <b className={"cursor-p"} onClick={addNewKey}>
                      Create one
                    </b>
                  </Label>
                ) : (
                  <div>
                    <Label className="">Security key</Label>
                    <FontAwesomeIcon
                      icon={faCog}
                      className="left5 cursor-p"
                      onClick={addNewKey}
                    />
                  </div>
                )}
              </div>
              <Select
                className="mb-2 react-select primary"
                classNamePrefix="react-select"
                placeholder="Choose key"
                name="keySelect"
                closeMenuOnSelect
                isMulti
                value={keySelect}
                onChange={(value) => setkeySelect(value)}
                options={keyOptions}
                isDisabled={keyOptions.length <= 1}
              />
              {errorSecurityKey && (
                <p className="error">Select at least 1 security key</p>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
