import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button, FormGroup, Label } from "reactstrap";
import { fetchForgotPassword } from "../../../../services/services";
import { validateEmail } from "../../../../utils";
import { CustomErrorField } from "../../../../views/components/CustomErrorField";

interface Values {
  email: string;
}

interface Error {
  email?: string;
}

export const ForgotPassword = ({ setForgotPassword }: any) => {
  const [msgErr, setMsgErr] = useState("");

  /**
   * reCaptcha code
   */
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    // ???
    await executeRecaptcha("signup");
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validate={(value) => {
        let errors: Error = {};

        if (!value.email) {
          errors.email = "This field is required";
        } else {
          if (validateEmail(value.email))
            errors.email = "Please, enter a valid email";
        }

        return errors;
      }}
      onSubmit={async (
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {
       
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }

        const token = await executeRecaptcha("yourAction");

        // Whats happend if we don't have the token?
        if (!token) return;

        let body = {
          email: values.email,
          token,
        };

        const { status, message } = await fetchForgotPassword(body);

        if (status === "Success") {
          setForgotPassword({ show: false, email: values.email, msg: message });
        } else {
          setMsgErr(message);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, errors, values }) => (
        <Form className=" sign-container-form">

          <div className="sign-container-title">
            <h1
              className="sign-container-title-h3"
              style={{ color: "rgb(14, 16, 26)" }}
            >
              Password recovery
            </h1>
          </div>

          <div className="sign-input-placeholder">
            <div className={`sign-input-suffix-container`}>
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Your email"
                className="sign-base-input"
              />
            </div>
            {false && errors.email && <div className='sign-message-placeholder'>
              <ErrorMessage name="email" component={CustomErrorField} />
            </div>}
          </div>
          
          <button
            className="btn_Submit"
            type="submit"
            disabled={isSubmitting || errors.email || values.email == ''}
          >
            Recover password
          </button>
          {msgErr && <div className='sign-message-placeholder'>
            <p className="error-field">{msgErr}</p>
          </div>}
        </Form>
      )}
    </Formik>
  );
};
