import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
} from "reactstrap";
import { ForgotPassword } from "../forms/ForgotPassword";
import { NewPassword } from "../forms/NewPassword";
import FrontendConfig from "../../assets/js/config";

const ForgotDefault = () => {
  const history = useHistory();

  const handleSignIn = (e: any) => {
    e.preventDefault();
    history.push("/auth/login");
  };

  const [forgotPassword, setForgotPassword] = useState({
    show: true,
    email: "",
    msg: "",
  });

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <div className="clearfix">
              <Card className="card-white card-forgot">
                <CardHeader
                  className={`card-header-sp ${
                    localStorage.getItem("isMobile")
                      ? "card-header-sp-mobile"
                      : ""
                  }`}
                >
                  <img
                    alt="..."
                    src={require("../../assets/img/sp.png").default}
                  />
                </CardHeader>
                <CardBody>
                  {forgotPassword.show && (
                    <ForgotPassword setForgotPassword={setForgotPassword} />
                  )}
                  {!forgotPassword.show && (
                    <NewPassword forgotPassword={forgotPassword} />
                  )}
                </CardBody>
                <CardFooter className="top0">
                  <hr />
                  <div className="center-sp">
                    <span className="form-check-sign" />
                    Go back to
                    <a
                      className="link footer-link left4"
                      href="/auth/login"
                      onClick={(e) => handleSignIn(e)}
                    >
                      Sign in
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Col>
        </Container>
      </div>
    </>
  );
};

const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/pages/Forgot");
  } catch (err) {
    return null;
  }
}


const Forgot = tryRequire() ? tryRequire().default : ForgotDefault;
export default Forgot;