import { getColorName } from "./settings";
import { faGlobe, faGlobeAmericas, faGlobeOceania } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UncontrolledTooltip
} from "reactstrap";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { validateEmail } from ".";

export const templateRequest = (settings) => {
  /* Default values for the body */
  let body = {
    /*
     *Accessibility (required, default value is false
     */
    lockedByOwner: settings.lockedByOwner ? settings.lockedByOwner : false,

    /*
     * Availability (required)
     * Pay attention for !
     */
    otl: !settings.availability.isSwitched,
  };

  if (settings.availability.isSwitched) {
    body.lockAfterUse = settings.availability.isSwitched2;
  }

  /*
   * Visibility (array of ip addresses)
   */
  if (settings.ips && settings.ips.length > 0) body.ipList = settings.ips;

  if (settings.label && settings.label.name && settings.label.color) {
    body.label = {
      codeName: settings.label.name,
      color: getColorName(settings.label.color),
    };
  }
  /*
   * Authorization (no required)
   */
  const pinAux =
    settings.authorization && settings.authorization.pin
      ? settings.authorization.pin
      : 0;

  let opt;
  if (
    settings.authorization &&
    settings.authorization.opt &&
    settings.authorization.opt === "Static"
  )
    opt = "Static";
  else if (settings.authorization && settings.authorization.opt === "Dynamic")
    opt = "Dynamic";
  else if (
    settings.authorization &&
    settings.authorization.opt === "Security key"
  )
    opt = "Security key";
  else if (
    settings.authorization &&
    settings.authorization.opt === "Email"
  )
    opt = "Email";

  if (pinAux && pinAux !== 0) {
    if (opt === "Dynamic")
      body.pin = {
        dynamic: pinAux,
      };
    else if (opt === "Static")
      body.pin = {
        static: pinAux,
      };
  }

  if (
    opt === "Security key" &&
    settings.authorization &&
    settings.authorization.keys &&
    settings.authorization.keys.length > 0
  ) {
    delete body.pin;
    body.keys = [];

    for (let i = 0, size = settings.authorization.keys.length; i < size; i++) {

      let secContentKey = {
        id: settings.authorization.keys[i]["id"],
        name:  settings.authorization.keys[i]["name"],
        value:  settings.authorization.keys[i]["valueAux"],
        transports:  JSON.parse(settings.authorization.keys[i]["transports"])
      };

      if(settings.authorization.keys[i]["labelAux"] && settings.authorization.keys[i]["labelAux"]["codeName"]){
        secContentKey["label"] = {
          codeName: settings.authorization.keys[i]["labelAux"]["codeName"],
          color: settings.authorization.keys[i]["labelAux"]["color"],
        };
      }
      
      body.keys.push(secContentKey);
    }
  }

  if(
    opt === "Email" &&
    settings.authorization.email &&
    !validateEmail(settings.authorization.email)
  ){
    body.emailVerification = {
      id: settings.authorization.email,
      hint: settings.authorization.hint? true: false
    };
  }

  /*
   * Availability (no required)
   */
  let qty = settings.availability.qty;
  if (qty) qty = parseInt(qty);

  let qty2 = settings.availability.qty2;
  if (qty2) qty2 = parseInt(qty2);

  // Available from
  const option = settings.availability.opt;
  const isRelative =
    option === "Minutes" || option === "Hours" || option === "Days";
  const isAbsolute = option === "Specific date";

  if (isRelative) {
    if (option === "Minutes") {
      body.availableFrom = {
        relative: qty * 60,
      };
    } else if (option === "Hours") {
      body.availableFrom = {
        relative: qty * 3600,
      };
    } else if (option === "Days") {
      body.availableFrom = {
        relative: qty * 3600 * 24,
      };
    }
  } else if (isAbsolute) {
    body.availableFrom = {
      absolute: settings.availability.dateTimeStart / 1000,
    };
  }

  /*
   * TTL
   */
  const option2 = settings.availability.opt2;
  const isRelative2 =
    option2 === "Minutes" ||
    option2 === "Hours" ||
    option2 === "Days" ||
    option2 === "Never expire";
  const isAbsolute2 = option2 === "Specific date";

  if (isRelative2) {
    if (option2 === "Minutes") {
      body.ttl = {
        relative: qty2 * 60,
      };
    } else if (option2 === "Hours") {
      body.ttl = {
        relative: qty2 * 3600,
      };
    } else if (option2 === "Days") {
      body.ttl = {
        relative: qty2 * 3600 * 24,
      };
    } else if (option2 === "Never expire") {
      body.ttl = {
        relative: 0,
      };
    }
  } else if (isAbsolute2) {
    body.ttl = {
      absolute: settings.availability.dateTimeEnd / 1000,
    };
  } // TODO why this case?
  else if (settings.ttl) {
    body.ttl = settings.ttl;
  }

  return body;
};

export const parseConfig = (details) => {
  // Start date
  let opt = "Right now";
  let qty;
  let dateTimeStart;

  // TTL - By default is relative in days
  let opt2 = "Days";
  let qty2;
  let dateTimeEnd;

  // AVAILABLE FROM
  if (details && details.availableFrom) {
    if (details.availableFrom.absolute) {
      opt = "Specific date";
      dateTimeStart = details.availableFrom.absolute * 1000;
    } else if (details.availableFrom.relative) {
      const relative = details.availableFrom.relative;

      if (relative % 86400 === 0) {
        opt = "Days";
        qty = relative / 86400;
      } else if (relative % 3600 === 0) {
        opt = "Hours";
        qty = relative / 3600;
      } else if (relative % 60 === 0) {
        opt = "Minutes";
        qty = relative / 60;
      }
    }
  }

  // --------------TTL--------------
  if (details && details.ttl) {
    if (details.ttl.absolute) {
      opt2 = "Specific date";
      dateTimeEnd = details.ttl.absolute * 1000;
    } else if (details.ttl.relative) {
      const relative = details.ttl.relative;

      if (relative % 86400 === 0) {
        opt2 = "Days";
        qty2 = relative / 86400;
      } else if (relative % 3600 === 0) {
        opt2 = "Hours";
        qty2 = relative / 3600;
      } else if (relative % 60 === 0) {
        opt2 = "Minutes";
        qty2 = relative / 60;
      }
    } else {
      opt2 = "Never expire";
    }
  }

  const availability = {
    isSwitched: !details.otl,
    isSwitched2: details.lockAfterUse || false,
    dateTimeEnd,
    dateTimeStart,
    opt,
    opt2,
    qty,
    qty2,
  };

  return { availability };
};

export const validateDate = (type, qty, datetime) => {
  let res = false;
  let time = 86400000;

  if (type === "Right now" && new Date().getTime() >= datetime) res = true;
  else if (type !== "Right now") {
    switch (type) {
      case "Minutes":
        time = 60000;
        break;
      case "Hours":
        time = 3600000;
        break;
      default:
        break;
    }

    res = new Date(new Date().getTime() + parseInt(qty) * time) >= datetime;
  }

  return res;
};

export const parseTemplates = (data, highlight = false) => {

  const globalIndicator = <FontAwesomeIcon
    icon={faGlobe}
    width="25px"
  />;

  let i = 0;
  data.forEach((temp) => {
    temp.label = highlight && temp.global? 
    <>
    <strong>{temp.codeName}</strong>
    <div id={`tooltipTemplate${i}`} className="pull-right">{globalIndicator}</div>
    <UncontrolledTooltip
      delay={0}
      target={`tooltipTemplate${i}`}
      placement="right"
    >
      Global
    </UncontrolledTooltip>
    </>
    : temp.codeName;
    temp.value = temp.codeName;
    temp.color = temp.starred ? "#1d8cf8" : "";
    i++;
  });

  data = data.sort(favoriteSort);

  return data;
};

const favoriteSort = (a, b) => {
  if (a.starred) {
    return -1;
  }
  if (!b.starred) {
    return 1;
  }
  return 0;
};
