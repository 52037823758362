import FrontendConfig from "../assets/js/config";


const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../envs/" + env + "/constants/newSecret");
  } catch (err) {
    return null;
  }
}

export const secretOptions = tryRequire() ? tryRequire().secretOptions 
:  [
  {
    value: "",
    label: "Select an option",
    isDisabled: true,
  },
  {
    value: "password",
    label: "Password",
    isDisabled: false,
  },
  {
    value: "message",
    label: "Message",
    isDisabled: false,
  },
  {
    value: "credentials",
    label: "Credentials",
    isDisabled: false,
  },
  {
    value: "custom",
    label: "JSON",
    isDisabled: false,
  },
  {
    value: "QR",
    label: "QR",
    isDisabled: false,
  }
];

export const parseCustomSecretSchemasOptions = (customSecretSchemas) => {

  let customSecretSchemasParsed = [];
  for(let i = 0, size = customSecretSchemas.length; i < size; i++){
    customSecretSchemasParsed.push({
      value: 'custom_secret_schema'+customSecretSchemas[i].id,
      label: <b>[C] - {customSecretSchemas[i].name}</b>,
      isDisabled: false,
    });
  }
  return customSecretSchemasParsed;
}

export const colors = tryRequire() ? tryRequire().colors 
:  [
  { name: "primary", value: "#0d6efd", label: "Blue" },
  { name: "info", value: "#1d8cf8", label: "Light blue" },
  { name: "success", value: "#00f2c3", label: "Green" },
  { name: "warning", value: "#ff8d72", label: "Orange" },
  { name: "danger", value: "#f63b3b", label: "Red" },
];

export const allColors = tryRequire() ? tryRequire().allColors 
: [
  { label: "Any", value: "any", color: "Any" },
  { name: "primary", value: "#0d6efd", label: "Blue" },
  { name: "info", value: "#1d8cf8", label: "Light blue" },
  { name: "success", value: "#00f2c3", label: "Green" },
  { name: "warning", value: "#ff8d72", label: "Orange" },
  { name: "danger", value: "#f63b3b", label: "Red" },
];

export const secretTypes = tryRequire() ? tryRequire().secretTypes 
: [
  { value: "any", label: "Any" },
  { value: "password", label: "Password" },
  { value: "message", label: "Message" },
  { value: "credentials", label: "Credential" },
  { value: "custom", label: "Json" },
  { value: "QR", label: "Qr" },
];
