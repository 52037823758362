export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !(re.test(String(email).toLowerCase()) && email.length <= 100);
};

export const validatePassword = (password) => {
  const re =
    /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
  return !re.test(String(password));
};

export const validateNameLastname = (string) => {
  return !(string.length <= 50);
};

export const validateVerificationCode = (code) => {
  //const re = /^[0-9]*$/;
  const re = /^[0-9]{6}$/;
  return !re.test(String(code));
};

export const isJsonString = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};

export const checkIpValue = (value, mask=false) => {

  if(mask && value.includes("/")){
    const maskValue = value.split("/");
    if(maskValue.length != 2){
      return false;
    }else{
      const subnetMask = parseInt(maskValue[1]);
      if(subnetMask && subnetMask > 32 || maskValue[1] && maskValue[1].includes(".")){
        return false;
      }
    }
    value = maskValue[0];
  }

  const subips = value.split(".");
  if (subips.length > 4) {
    return false;
  }
  const invalidSubips = subips.filter((ip) => {
    ip = parseInt(ip);
    return ip < 0 || ip > 255;
  });
  if (invalidSubips.length !== 0) {
    return false;
  }
  let emptyIpCount = 0;
  subips.forEach((ip) => {
    if (ip === "") {
      emptyIpCount++;
    }
  });
  if (emptyIpCount > 1) {
    return false;
  }
  return true;
};

export const orderByAttribute = (obj, orderAttribute) => {
  // create an array of keys from the object
  const keys = Object.keys(obj);
  
  // sort the keys based on the order attribute
  keys.sort((a, b) => obj[a][orderAttribute] - obj[b][orderAttribute]);
  
  // create a new object with the sorted keys and values
  const orderedObj = {};
  for (const key of keys) {
    orderedObj[key] = obj[key];
  }
  
  return orderedObj;
}