import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  CardHeader,
  Collapse,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { dataTypes, types } from "../../context/types";
import {
  fetchSetDefaultTemplate,
  fetchUpdateGeneralSettings
} from "../../services/services";
import { fetchLabels } from "../../services/settings";
import { parseClassLabels } from "../../utils/settings";
import { parseConfig, templateRequest } from "../../utils/templates";
import { CustomModalLabel } from "../components/CustomModalLabel";
import { CustomErrorField } from "./CustomErrorField";
import { SecretSettings } from "./SecretSettings";

export const DefaultInputs = ({ tabName, dispatchProfile, profile }: any) => {
  console.log("DefaultInputs");
  const [config, setConfig] = useState({
    lockedByOwner: false,
    data: null,
    error: false,
    availability: {
      isSwitched: false,
      isSwitched2: true,
      dateTimeEnd: undefined,
      dateTimeStart: undefined,
      opt: "Right now",
      opt2: "Days",
      qty: undefined,
      qty2: 7,
    },
    authorization: { opt: "No pin", pin: 0 },
  });

  const [qty2Aux, setQtyAux] = useState(0);

  const history = useHistory();

  // Get name from context
  const { 
    dispatch,
    user: { purposeMode },
  } = useContext(Context);

  // Notify
  const { addNotify } = useAPIError();

  /* Store initial values to the form */
  const [initialFormValues, setInitialFormValues] = useState({
    //enter: false,
    private:
      profile &&
      (
        purposeMode === 0 && (profile.defaultSecretTemplate &&
        profile.defaultSecretTemplate.defaultTemplate &&
        profile.defaultSecretTemplate.defaultTemplate.privateDescription) 
        ||      
        purposeMode === 1 && (profile.defaultSecretTemplateReceive &&
        profile.defaultSecretTemplateReceive.defaultTemplate &&
        profile.defaultSecretTemplateReceive.defaultTemplate.privateDescription)
      )
        ? 
          purposeMode === 1 ? 
          profile.defaultSecretTemplateReceive.defaultTemplate.privateDescription 
          : 
          profile.defaultSecretTemplate.defaultTemplate.privateDescription
        : "",
    public:
      profile &&
      (
        purposeMode === 0 && (profile.defaultSecretTemplate &&
        profile.defaultSecretTemplate.defaultTemplate &&
        profile.defaultSecretTemplate.defaultTemplate.publicDescription)
        ||
        purposeMode === 1 && (profile.defaultSecretTemplateReceive &&
        profile.defaultSecretTemplateReceive.defaultTemplate &&
        profile.defaultSecretTemplateReceive.defaultTemplate.publicDescription)
      )
        ? purposeMode === 1 ? profile.defaultSecretTemplateReceive.defaultTemplate.publicDescription : profile.defaultSecretTemplate.defaultTemplate.publicDescription
        : "",
  });

  const [initialText, setInitialText] = useState({
    //enter: false,
    text: profile && 
    (
      purposeMode === 0 && profile.revealMessage
       || 
      purposeMode === 1 && profile.sendMessage
    ) ?
    purposeMode === 1 ? profile.sendMessage : profile.revealMessage : "",
  });

  const [template, setTemplate] = useState(
    profile &&
      (
        purposeMode === 0 && (profile.defaultSecretTemplate &&
        profile.defaultSecretTemplate.defaultTemplate) 
        ||
        purposeMode === 1 && (profile.defaultSecretTemplateReceive &&
        profile.defaultSecretTemplateReceive.defaultTemplate) 
      )
      ? purposeMode === 1 ? profile.defaultSecretTemplateReceive.defaultTemplate : profile.defaultSecretTemplate.defaultTemplate
      : null
  );

  /* Show label modal */
  const [modalClassic, setModalClassic] = useState(false);
  const [multipleSelect, setmultipleSelect] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  /*useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        if (details.length > 0) {
          const auxLabels = parseClassLabels(details);
          setLabelOptions([
            {
              label: "Select an option",
              value: "Select an option",
              isDisabled: true,
            },
            ...auxLabels,
          ]);
        }
      }
    };

    if (labelOptions.length === 0) getLabels();
  }, [labelOptions]);*/

  /*
   * We use this condition to put only one label, if we want to add more labels
   * in the future, we will need to delete this condition.
   */
  useEffect(() => {
    if (multipleSelect.length > 1) {
      setmultipleSelect((prevValues) => [prevValues[1]]);
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      label: {
        name: multipleSelect && multipleSelect[0] && multipleSelect[0].label,
        color: multipleSelect && multipleSelect[0] && multipleSelect[0].color,
      },
    }));
  }, [multipleSelect]);

  const addNewLabel = () => {
    setModalClassic(!modalClassic);
  };

  /*
   * Listen close modal
   * After close modal (maybe succes or cancelled by the user) we need to update the array of labels
   * In the future, we will remove, and by replaced by hooks of redux or context.
   */
  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxLabels = parseClassLabels(details);

        setLabelOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxLabels,
        ]);
      }
    };

    if (!modalClassic) getLabels();
  }, [modalClassic]);

  useEffect(() => {
    if (template /* && !initialFormValues.enter*/) {
      const details = template;
      // Initial values
      // Name, public and private description
      setInitialFormValues({
        //enter: true,
        private: details.privateDescription ? details.privateDescription : "",
        public: details.publicDescription ? details.publicDescription : "",
      });

      setInitialText({
        text: purposeMode === 1 ? profile.sendMessage : profile.revealMessage,
      });

      if (details.label && details.label.codeName) {
        let tempLabel = structuredClone(details.label);
        setmultipleSelect(parseClassLabels([tempLabel]));
      }

      let configAux: any = {
        // Visibility (1)
        ips: details.ipList || [],
      };

      // Availability (2)
      const { availability } = parseConfig(details);

      if (availability.qty2) {
        setQtyAux(availability.qty2);
      }
      configAux.availability = availability;

      // Accesibility (3)
      configAux.lockedByOwner = details.lockedByOwner;

      // Authorization (4)
      let auhtorizationAux: any = {};
      if (details.pin && details.pin.static) {
        auhtorizationAux.opt = "Static";
        auhtorizationAux.pin = details.pin.static;
      } else if (details.pin && details.pin.dynamic) {
        auhtorizationAux.opt = "Dynamic";
        auhtorizationAux.pin = details.pin.dynamic;
      }else if(details.keys && details.keys.length > 0){
        auhtorizationAux.opt = "Security key";
        auhtorizationAux.keys = [];
        for(let i = 0, size = details.keys.length; i < size; i++){
          
          auhtorizationAux.keys.push(details.keys[i]); 

          auhtorizationAux.keys[i].labelAux = details.keys[i].label;
          auhtorizationAux.keys[i].valueAux = details.keys[i].value;
          auhtorizationAux.keys[i].label = details.keys[i].name;
          auhtorizationAux.keys[i].value = details.keys[i].id;
        }
        
      } else if (details.emailVerification && details.emailVerification.id) {
        auhtorizationAux.opt = "Email";
        auhtorizationAux.email = details.emailVerification.id;
        auhtorizationAux.hint = details.emailVerification.hint;
      }

      if (auhtorizationAux.pin || auhtorizationAux.keys || auhtorizationAux.email) {
        configAux.authorization = auhtorizationAux;
      }

      setConfig((prevConfig) => ({
        ...prevConfig,
        ...configAux,
        update: true,
      }));
    }
  }, [template]);

  const [openedCollapseSettings, setOpenedCollapseSettings] = useState(true);
  
  return (
    <>
      <Row>
        <Col lg="12" md="12">
          <Card className="custom-card profile">
            <CardBody>
              <CardTitle tag="h3">Default template</CardTitle>
              <Row>
                <Col lg="12" md="12">
                  <p>Configure your default template for sharing secrets</p>
                  <Row>
                    <Col md="12">
                      <Formik
                        initialValues={initialFormValues}
                        validate={(values) => {
                          let errors: any = {};

                          return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                          const bodyAux = templateRequest(config);

                          let body: any = {
                            ...bodyAux,
                          };

                          if (values.private) {
                            body.privateDescription = values.private;
                          }

                          if (values.public) {
                            body.publicDescription = values.public;
                          }

                          let { status, message, redirect } =
                            await fetchSetDefaultTemplate(body);

                          if (redirect) {
                            dispatch({
                              type: types.logout,
                            });
                            history.push("/auth/login");
                          } else if (status === "Success") {
                            setSubmitting(false);
                            addNotify(
                              "The template was successfully updated",
                              "success"
                            );

                            
                            
                            if(body.keys && body.keys.length > 0){
                              body.keys.forEach((element:any) => {
                                element.transports = JSON.stringify(element.transports);
                              });
                            }

                            if(purposeMode === 1){
                              profile.defaultSecretTemplateReceive.defaultTemplate =
                                body;
                            } else {
                              profile.defaultSecretTemplate.defaultTemplate =
                                body;
                            }

                            dispatchProfile({
                              type: dataTypes.profile,
                              payload: { ...profile, profileTabName: tabName },
                            });
                          } else {
                            addNotify(
                              `An error has ocurred when trying to create a new template.
                      ${message}`,
                              "danger"
                            );
                          }
                          return 1;
                        }}
                      >
                        {({ isSubmitting }) => (
                          <Form className="form-formik">
                            <Row>
                              <Col md={6}>
                                <div className="box-label">
                                  {labelOptions.length <= 1 ? (
                                    <Label>
                                      You don't have a label yet.{" "}
                                      <b
                                        className={"cursor-p"}
                                        onClick={addNewLabel}
                                      >
                                        Create one
                                      </b>
                                    </Label>
                                  ) : (
                                    <div>
                                      <Label className="">Label</Label>
                                      <FontAwesomeIcon
                                        icon={faCog}
                                        className="left5 cursor-p"
                                        onClick={addNewLabel}
                                      />
                                    </div>
                                  )}
                                </div>
                                <CustomModalLabel
                                  isOpen={modalClassic}
                                  toggle={addNewLabel}
                                />
                                <Select
                                  className={`mb-2 react-select ${
                                    multipleSelect[0]
                                      ? multipleSelect[0].color
                                      : "mb-2 info"
                                  }`}
                                  classNamePrefix="react-select"
                                  placeholder="Choose label"
                                  name="multipleSelect"
                                  closeMenuOnSelect
                                  isMulti
                                  value={multipleSelect}
                                  onChange={(value) => setmultipleSelect(value)}
                                  options={labelOptions}
                                  isDisabled={labelOptions.length <= 1}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Label>Public description</Label>
                                <FontAwesomeIcon
                                  id="publicDescription"
                                  icon={faInfoCircle}
                                  color="#0d6efd"
                                  width="40px"
                                  className="left4"
                                />
                                <UncontrolledTooltip
                                  delay={0}
                                  target="publicDescription"
                                  placement="right"
                                >
                                  This description will be shown to those users
                                  that access the link, describe here the
                                  purpose or the content of the secret being
                                  shared.
                                </UncontrolledTooltip>
                                <FormGroup>
                                  <Field type="text" name="public" />
                                </FormGroup>
                                <ErrorMessage
                                  name="public"
                                  component={CustomErrorField}
                                />
                              </Col>
                              <Col md={6}>
                                <Label>
                                  Private description
                                  <FontAwesomeIcon
                                    id="pDescription"
                                    icon={faInfoCircle}
                                    color="#0d6efd"
                                    width="40px"
                                    className="left4"
                                  />
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="pDescription"
                                    placement="right"
                                  >
                                    This description is private for your own use
                                    to be able to identify secrets easily.
                                  </UncontrolledTooltip>
                                </Label>
                                <FormGroup>
                                  <Field type="text" name="private" />
                                </FormGroup>
                                <ErrorMessage
                                  name="private"
                                  component={CustomErrorField}
                                />
                              </Col>
                            </Row>

                            <div
                              aria-multiselectable={true}
                              className="card-collapse"
                              id="accordion"
                              role="tablist"
                            >
                              <Card className="card-plain">
                                <CardHeader role="tab">
                                  <a
                                    aria-expanded={openedCollapseSettings}
                                    href="#pabo"
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={(e) => {
                                      e.preventDefault();                                
                                      setOpenedCollapseSettings(!openedCollapseSettings); 
                                    }}
                                  >
                                    Security settings
                                    <i className="tim-icons icon-minimal-down" />
                                  </a>
                                </CardHeader>
                                <Collapse
                                  role="tabpanel"
                                  isOpen={openedCollapseSettings}
                                  className="collapse-settings"
                                >
                                  <CardBody>
                                    <Card className="card-plain card-subcategories">
                                      <CardBody>
                                        <SecretSettings
                                          setConfig={setConfig}
                                          config={config}
                                          qty2Aux={qty2Aux}
                                        />
                                      </CardBody>
                                    </Card>
                                  </CardBody>
                                </Collapse>
                              </Card>
                            </div>    

                            <Row className="mt-1">
                              <Col md={6}>
                                <Button
                                  className="btn-fill btn-sm"
                                  color="primary"
                                  type="submit"
                                  disabled={isSubmitting || config.error}
                                >
                                  {!isSubmitting ? "Ok" : "PLEASE WAIT..."}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12" md="12">
          <Card className="custom-card profile">
            <CardBody>
              <CardTitle tag="h3">Secrets link</CardTitle>
              <Row>
                <Col lg="12" md="12">
                  <p>Change the default text for generated links</p>
                  <Row>
                    <Col md="12">
                      <Formik
                        initialValues={initialText}
                        validate={(values) => {
                          let errors: {
                            text?: string;
                          } = {};
                          const error = "This field is required";

                          if (!values.text) {
                            errors.text = error;
                          }

                          return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                          let body: any = {};

                          if (values.text) {
                            if(purposeMode === 1){
                              body.sendMessage = values.text;
                              profile.sendMessage = values.text;
                            }else{
                              body.revealMessage = values.text;
                              profile.revealMessage = values.text;
                            }
                          }

                          let { status, message, redirect } =
                            await fetchUpdateGeneralSettings(body);

                          if (redirect) {
                            dispatch({
                              type: types.logout,
                            });
                            history.push("/auth/login");
                          } else if (status === "Success") {
                            setSubmitting(false);
                            addNotify(
                              "The text was successfully updated",
                              "success"
                            );

                            dispatchProfile({
                              type: dataTypes.profile,
                              payload: { ...profile, profileTabName: tabName },
                            });
                          } else {
                            addNotify(
                              `An error has ocurred when trying to create a new template.
                ${message}`,
                              "danger"
                            );
                          }
                          return 1;
                        }}
                      >
                        {({ isSubmitting }) => (
                          <Form className="form-formik">
                            <Row>
                              <Col md={6}>
                                <Label></Label>

                                <FormGroup>
                                  <Field type="text" name="text" />
                                </FormGroup>
                                <ErrorMessage
                                  name="text"
                                  component={CustomErrorField}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-1">
                              <Col md={6}>
                                <Button
                                  className="btn-fill btn-sm"
                                  color="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {!isSubmitting ? "Ok" : "PLEASE WAIT..."}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {false && <Row>
        <Col lg="12" md="12">
          <Card className="custom-card profile">
            <CardBody>
              <CardTitle tag="h3">Keyboard extension</CardTitle>
              <Row>
                <Col lg="12" md="12">
                  <p>Change the default text for generated links</p>
                  <Row>
                    <Col md="12">
                      <Formik
                        initialValues={initialText}
                        validate={(values) => {
                          let errors: {
                            text?: string;
                          } = {};
                          const error = "This field is required";

                          if (!values.text) {
                            errors.text = error;
                          }

                          return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                          let body: any = {};

                          if (values.text) {
                            body.revealMessage = values.text;
                            profile.revealMessage = values.text;
                          }

                          let { status, message, redirect } =
                            await fetchUpdateGeneralSettings(body);

                          if (redirect) {
                            dispatch({
                              type: types.logout,
                            });
                            history.push("/auth/login");
                          } else if (status === "Success") {
                            setSubmitting(false);
                            addNotify(
                              "The text was successfully updated",
                              "success"
                            );

                            dispatchProfile({
                              type: dataTypes.profile,
                              payload: { ...profile, profileTabName: tabName },
                            });
                          } else {
                            addNotify(
                              `An error has ocurred when trying to create a new template.
                ${message}`,
                              "danger"
                            );
                          }
                          return 1;
                        }}
                      >
                        {({ isSubmitting }) => (
                          <Form className="form-formik">
                            <Row>
                              <Col md={6}>
                                <Label></Label>

                                <FormGroup>
                                  <Field type="text" name="text" />
                                </FormGroup>
                                <ErrorMessage
                                  name="text"
                                  component={CustomErrorField}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-1">
                              <Col md={6}>
                                <Button
                                  className="btn-fill btn-sm"
                                  color="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {!isSubmitting ? "Ok" : "PLEASE WAIT..."}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>}
    </>
  );
};
