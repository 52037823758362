import {
  faEnvelope,
  faLock,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { fetchResetPassword } from "../../../../services/services";
import { validatePassword, validateVerificationCode } from "../../../../utils";
import { CustomErrorField } from "../../../../views/components/CustomErrorField";

interface Param {
  forgotPassword: {
    show: boolean;
    email: string;
    msg: string;
  };
}

interface Error {
  email?: string;
  password: string;
  password2: string;
  verification: string;
}

interface Values {
  email?: string;
  password: string;
  password2: string;
  verification: string;
}

export const NewPassword = ({ forgotPassword }: Param) => {
  const { email /* , msg */ } = forgotPassword;

  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  return (
    <div>
      <div>
        <div className="alert alert-info">
          <i className="fas fa-info-circle right5" />
          We have sent you the verification code to your email account
        </div>
      </div>
      <Formik
        initialValues={{
          email,
          password: "",
          password2: "",
          verification: "",
        }}
        validate={(values) => {
          let errors: Error = {};
          
          if (!values.password) {
            errors.password = "This field is required";
          } else if (validatePassword(values.password)) {
            errors.password =
              "Please, enter a valid password. Minimum 8 characters, must contain 1 number, 1 lowercase, 1 uppercase and 1 symbol (!@#$&*).";
          }

          if (!values.password2) {
            errors.password2 = "This field is required";
          } else if (validatePassword(values.password2)) {
            errors.password2 = "Please, enter a valid password";
          }

          if (
            values.password &&
            values.password2 &&
            values.password !== values.password2
          ) {
            errors.password2 = "Password confirmation doesn't match";
          }

          if (!values.verification) {
            errors.verification = "This field is required";
          } else if (validateVerificationCode(values.verification)) {
            errors.verification = "Please, enter a valid code";
          }

          return errors;
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {

          let body = {
            code: values.verification,
            email: values.email,
            password: values.password,
          };

          const { status, message } = await fetchResetPassword(body);
          
          if (status === "Success") {
            setMsg(message);
            setMsgErr("");
            setIsChanged(true);
          } else {
            setMsgErr(message);
          }
        }}
      >
        {({ isSubmitting, errors, values }) => (
          <Form className="sign-container-form">
            <div className="sign-container-title">
              <h1
                className="sign-container-title-h3"
                style={{ color: "rgb(14, 16, 26)" }}
              >
                New Password
              </h1>
            </div>

            <div className="sign-input-placeholder">
              <div className={`sign-input-suffix-container`}>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Your email"
                  className="sign-base-input"
                  disabled
                />
              </div>
              {errors.email && errors.email != '' && <div className='sign-message-placeholder'>
                <ErrorMessage name="email" component={CustomErrorField} />
              </div>}
            </div>
            
            
            
            <div className="sign-input-placeholder">
              <div className={`sign-input-suffix-container ${errors.password && values.password != '' ? "input-with-errors": ""}`}>
                <Field
                  id="password"
                  name="password"
                  placeholder="New password"
                  className="sign-base-input"
                  type="password"
                />
              </div>
            </div>
            
            {errors.password && values.password != '' && <div className='sign-message-placeholder'>
                <ErrorMessage name="password" component={CustomErrorField} />
              </div>}

            <div className="sign-input-placeholder">
              <div className={`sign-input-suffix-container ${errors.password2 && values.password2 != '' ? "input-with-errors": ""}`}>
                <Field
                  id="password2"
                  name="password2"
                  placeholder="Confirm password"
                  className="sign-base-input"
                  type="password"
                />
              </div>
              
            </div>

            {errors.password2 && values.password2 != '' &&
              <div className='sign-message-placeholder'>
                <ErrorMessage name="password2" component={CustomErrorField} />
              </div>}

            <div className="sign-input-placeholder">
              <div className={`sign-input-suffix-container ${errors.verification && values.verification != '' ? "input-with-errors": ""}`}>
                <Field
                  id="verification"
                  name="verification"
                  placeholder="Verification code"
                  className="sign-base-input"
                  type="text"
                />
              </div>
              
            </div>

            {errors.verification && values.verification != '' && <div className='sign-message-placeholder'>
                <ErrorMessage name="verification" component={CustomErrorField} />
              </div>}

            <button
              className="btn_Submit"
              type="submit"
              disabled={isSubmitting || isChanged || errors.verification || errors.password2 || errors.password ||
              values.verification == '' || values.password == '' || values.password2 == ''}
            >
              Reset password
            </button>
          </Form>
        )}
      </Formik>
      <div className="text-center top10">
        {msgErr === "" && <p className="reset-info-success">{msg}</p>}
        {msgErr !== "" && <div className='sign-message-placeholder'><p className="error-field">{msgErr}</p></div>}
      </div>
    </div>
  );
};
