import { useEffect, useState } from "react";
import { CustomIOSSpinner } from "../../components/Spinner/CustomIOSSpinner";
import { fetchUpdateGeneralSettings } from "../../services/services";
import { generateRSAKeyPair } from "../../utils/crypto";

export const MobileEnvelope = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const masterKey = decodeURIComponent(
    window.location.search.replace("?mkey=", "")
  );

  console.log("masterKey :", masterKey);
  console.log("window.location.search :", window.location.search);

  useEffect(() => {
    console.log("masterKey: ", masterKey);
    console.log("masterKey length: ", masterKey.length);

    const createMasterKey = async () => {
      let keyPairData = await generateRSAKeyPair(masterKey);

      if (keyPairData) {
        let body = {
          keyPair: {
            alg: "rsa",
            publicKey: keyPairData["publicKey"],
            sealedPrivateKey: keyPairData["sealedPrivateKey"],
            iv: keyPairData["iv"],
            active: true,
          },
        };

        const { status } = await fetchUpdateGeneralSettings(body);
        console.log("status: ", status);

        if (status === "Success") {
          setIsLoading(false);
          window.location = "sharepassapp://app/mkey/success";
        } else {
          window.location = "sharepassapp://app/mkey/error";
        }
      } else {
        window.location = "sharepassapp://app/mkey/error";
      }
    };

    if (masterKey.length === 32) createMasterKey();
  }, [masterKey]);

  return (
    <div
      className="content"
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {isLoading && <CustomIOSSpinner />}
    </div>
  );
};

// {isSuccess && <p>Request successful!</p>}
// {errorMessage && <p>{errorMessage}</p>}
