import { WidgetProps } from '@rjsf/utils';
import ReactInputMask from "react-input-mask";
import { checkIpValue } from "../../../utils";


function IpFullInputWidget(props: WidgetProps) {
  const { options } = props;
  //const { color, backgroundColor } = options;
  
  return <ReactInputMask
        formatChars={{
            9: "[0-9./]",
        }}
        className="custon-input-mask"
        mask="99999999999999999999"
        maskChar={null}
        alwaysShowMask={false}
        beforeMaskedValueChange={(newState, oldState, userInput) => {
            let value = newState.value;
            const oldValue = oldState.value;
            let selection = newState.selection;
            let cursorPosition = selection ? selection.start : null;
            const result = checkIpValue(value, true);
            if (!result) {
                value = value.trim();
                // try to add . before the last char to see if it is valid ip address
                const newValue =
                    value.substring(0, value.length - 1) +
                    "." +
                    value.substring(value.length - 1);
                if (checkIpValue(newValue, true)) {
                    cursorPosition++;
                    selection = { start: cursorPosition, end: cursorPosition };
                    value = newValue;
                } else {
                    const newValue =
                    value.substring(0, value.length - 1) +
                    "/" +
                    value.substring(value.length - 1);

                    if (checkIpValue(newValue, true)) {
                        cursorPosition++;
                        selection = { start: cursorPosition, end: cursorPosition };
                        value = newValue;
                    } else {
                        value = oldValue;
                    }
                }
            }

            return {
            value,
            selection,
            };
        }}
        value={props.value}
        required={props.required}
        onChange={(event: any) => props.onChange(event.target.value)}
    />;
}

export default IpFullInputWidget;