import FrontendConfig from "./config.js";

const InstanceConfig = () => {
  const config = {
    ymtechone: {
      envelopeLogo: require("../../envs/ymtechone/assets/img/ymtech.png"),
      qrLogo: "",
      envelope: true,
      emailSignUp: false,
      customSecretSchema: true,
      emailAuth: true,
      securityKeyAuth: true,
    },
    silentbreach: {
      envelopeLogo: require("../../envs/silentbreach/assets/img/logo.png"),
      qrLogo: "",
      envelope: true,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    schur: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: require("../../assets/img/sp.png"),
      envelope: false,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    natan: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: "",
      envelope: false,
      emailSignUp: false,
      customSecretSchema: true,
      emailAuth: false,
      securityKeyAuth: true,
    },
    centrevision: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: "",
      envelope: true,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    ahp: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: "",
      envelope: false,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    cfcsea: {
      envelopeLogo: require("../../envs/cfcsea/assets/img/cfcsea-logo.png"),
      qrLogo: "",
      envelope: true,
      emailSignUp: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    prod: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: require("../../assets/img/sp.png"),
      envelope: false,
      emailSignUp: true,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: false,
    },
    tst: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: require("../../assets/img/sp.png"),
      envelope: true,
      emailSignUp: true,
      customSecretSchema: true,
      emailAuth: true,
      securityKeyAuth: true,
    },
    staging: {
      envelopeLogo: require("../../assets/img/sp.png"),
      qrLogo: require("../../assets/img/sp.png"),
      envelope: false,
      emailSignUp: true,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: false,
    },
    kbi: {
      envelopeLogo: require("../../envs/kbi/assets/img/logo.png"),
      qrLogo: "",
      envelope: false,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
    arcare: {
      envelopeLogo: require("../../envs/arcare/assets/img/logo.png"),
      qrLogo: "",
      envelope: true,
      emailSignUp: false,
      customSecretSchema: false,
      emailAuth: false,
      securityKeyAuth: true,
    },
  };

  return config[FrontendConfig.env];
};

export default InstanceConfig;
