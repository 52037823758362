import './auth.css';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
} from "reactstrap";
import { ForgotPassword } from '../forms/ForgotPassword';
import { NewPassword } from '../forms/NewPassword';

const Forgot = () => {
  const history = useHistory();

  const handleSignIn = (e: any) => {
    e.preventDefault();
    history.push("/auth/login");
  };

  const [forgotPassword, setForgotPassword] = useState({
    show: true,
    email: "",
    msg: "",
  });

  return (
    <>
      <div className="sign-main-content">
        <div className='sign-container'>
          <div className="sign-container-bordered">
            {forgotPassword.show && (
              <ForgotPassword setForgotPassword={setForgotPassword} />
            )}
            {!forgotPassword.show && (
              <NewPassword forgotPassword={forgotPassword} />
            )}

            <hr />

            <div className="center-sp">
              <span className="form-check-sign" />
              Go back to{"  "}
              <a
                className="sign-base-unstyled sign-troubleshoot-link-inner"
                href="/auth/login"
                onClick={(e) => handleSignIn(e)}
                style={{marginLeft: '5px'}}
              >
                {"  "}Sign in
              </a>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;