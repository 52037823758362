import { useRef, useState } from "react";

import { EnvelopeIncompleteURL } from "../../../../views/components/EnvelopeIncompleteURL";
import { EnvelopeOpen } from "../../../../views/components/EnvelopeOpen";

const EnvelopeComplete = () => {

  const secretPart = window.location.pathname.split('/');
	const currentPath = window.location.hash.split('/');

  const fullId = secretPart[3] + currentPath[1];

  return (
    
    
      
      <div className="sign-main-content">
        {(currentPath.length != 2 && currentPath.length != 3) || secretPart.length != 5?
        <div className="content">
          <EnvelopeIncompleteURL/>
        </div>
        :
        <EnvelopeOpen id={fullId} pin={currentPath.length == 3 ? currentPath[2] : null}/>}
      </div>

  );
};

export default EnvelopeComplete;
