import { faRedoAlt, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import clipboardCheckW from "../../assets/img/clipboard-check-white.svg";
import clipboardCheck from "../../assets/img/clipboard-check.svg";
import clipboard from "../../assets/img/clipboard.svg";
import useAPIError from "../../commons/hooks/useAPIError";
import { URL_VIEW, URL_APP } from "../../constants/urls";
import { isMobileTablet } from "../../utils/secret";
import { parseConfig } from "../../utils/templates";

export const SecretSuccess = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  const [expireIn, setExpireIn] = useState({ title: "", info: "" });

  const [copy, setCopy] = useState(false);
  const setCopied = () => {
    addNotify("Link copied!", "success");
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1200);
  };

  const [url, setUrl] = useState("");

  const [settings, setSettings] = useState(null);

  const [availableFromDate, setAvailableFromDate] = useState(null);

  useEffect(() => {
    const state = history.location.state;

    if (!state) {
      history.push("/admin/dashboard");
    } else {
      const { expiryRelativeText, expiryRelative, secretId, randomWord, body } =
        state.data;

      setSettings(body);

      let pinString = "";
      if (body.pin) {
        if (body.pin.dynamic) {
          pinString = "/dpin";
        } else {
          pinString = "/pin";
        }
      }

      const secretIdParts = [
        encodeURIComponent(secretId).substring(0, 15),
        encodeURIComponent(secretId).substring(15),
      ];

      if(randomWord){
        setUrl(
          `${URL_VIEW}/${encodeURIComponent(
            secretIdParts[0]
          )}/#/${encodeURIComponent(secretIdParts[1])}/${encodeURIComponent(
            randomWord
          )}${pinString}`
        );
      }else{
        setUrl(
          `${URL_APP}/auth/envelope/${encodeURIComponent(
            secretIdParts[0]
          )}/#/${encodeURIComponent(secretIdParts[1])}${pinString}`
        );
      }

      setExpireIn({ title: expiryRelativeText, info: expiryRelative });
    }
  }, [history]);

  const goToNewSecret = () => {
    history.push("/admin/secret/new-secret");
  };

  let parsedConfig = {};
  if (settings) {
    parsedConfig = parseConfig(settings);
  }

  let settingsAux = [];

  /**
   * Settings parsing for summary
   */
  if (
    settings &&
    settings.label &&
    settings.label.codeName &&
    settings.label.color
  ) {
    settingsAux.push(
      <div>
        <div id="label-options" className="btn-icon btn-round btn btn-xl">
          <div>
            <FontAwesomeIcon
              icon={faTag}
              color={settings.label.color}
              className="fa-wizard-badges mt-3"
            />
          </div>
          <div>
            <small>
              {settings.label.codeName.length > 10
                ? settings.label.codeName.substring(0, 7) + "..."
                : settings.label.codeName}
            </small>
          </div>
        </div>
        <UncontrolledTooltip delay={0} target={`label-options`} placement="top">
        Labelled:
          <br />
          "
          <FontAwesomeIcon
            id="PinTooltip"
            className="right3"
            icon={faTag}
            color={settings.label.color}
          />
          {" " + settings.label.codeName}"
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && settings.publicDescription) {
    settingsAux.push(
      <div>
        <div id="public-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-list fa-wizard-badges mt-3"></div>
          <div>Public</div>
        </div>
        <UncontrolledTooltip
          delay={0}
          target={`public-options`}
          placement="top"
        >
          The recipient will see this description when opening it:
          <br />
          <q>{settings.publicDescription}</q>
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && settings.privateDescription) {
    settingsAux.push(
      <div>
        <div id="private-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-list-alt fa-wizard-badges mt-3"></div>
          <div>Private</div>
        </div>
        <UncontrolledTooltip
          delay={0}
          target={`private-options`}
          placement="top"
        >
          You will see the following description in the secrets' list:
          <br />
          <q>{settings.privateDescription}</q>
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && settings.lockedByOwner) {
    settingsAux.push(
      <div>
        <div id="locked-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-lock fa-wizard-badges mt-3"></div>
          <div>Locked</div>
        </div>
        <UncontrolledTooltip
          delay={0}
          target={`locked-options`}
          placement="top"
        >
          Won't be accessible for recipients until you unlock it.
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && !settings.otl) {
    settingsAux.push(
      <div>
        <div
          id="otl-options"
          className="btn-icon btn-round btn btn-xl btn-wizard-red"
        >
          <div className="fa fa-times fa-wizard-badges mt-3"></div>
          <div>OTL</div>
        </div>
        <UncontrolledTooltip delay={0} target={`otl-options`} placement="top">
          "Allow multiple uses" option is enabled, the link can be opened
          multiple times.
        </UncontrolledTooltip>
      </div>
    );
  } else if (settings && settings.otl) {
    settingsAux.push(
      <div>
        <div id="otl-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-link fa-wizard-badges mt-3"></div>
          <div>OTL</div>
        </div>
        <UncontrolledTooltip delay={0} target={`otl-options`} placement="top">
          One time link. It can be opened just 1 time.
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && !settings.otl && settings.lockAfterUse) {
    settingsAux.push(
      <div>
        <div id="unlock-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-unlock fa-wizard-badges mt-3"></div>
          <div>Per use</div>
        </div>
        <UncontrolledTooltip
          delay={0}
          target={`unlock-options`}
          placement="top"
        >
          It will get locked after each time it gets opened, so recipients wont
          be able to access it until you unlock it.
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && settings.ipList && settings.ipList.length > 0) {
    settingsAux.push(
      <div>
        <div id="ip-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-map-marker-alt fa-wizard-badges mt-2"></div>
          <div>
            <small>Restricted</small>
          </div>
          <div>
            <small>by IP</small>
          </div>
        </div>
        <UncontrolledTooltip delay={0} target={`ip-options`} placement="top">
          Visible by:
          {false && settings.ipList.length > 1 ? "s" : ""}:{" "}
          <b>
            <i>{settings.ipList.join(", ")}</i>
          </b>
          {false && (
            <ul>
              {settings.ipList.map((item, index) => (
                <li className="monospace-font">{item}</li>
              ))}
            </ul>
          )}
        </UncontrolledTooltip>
      </div>
    );
  }

  if (settings && settings.pin && settings.pin.static) {
    settingsAux.push(
      <div>
        <div id="pin-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-asterisk fa-wizard-badges mt-3"></div>
          <div>PIN code</div>
        </div>
        <UncontrolledTooltip delay={0} target={`pin-options`} placement="top">
          The recipient will need to enter a static pin code to get the secret.
        </UncontrolledTooltip>
      </div>
    );
  } else if (settings && settings.pin) {
    settingsAux.push(
      <div>
        <div id="pin-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-asterisk fa-wizard-badges mt-3"></div>
          <div>MFA code</div>
        </div>
        <UncontrolledTooltip delay={0} target={`pin-options`} placement="top">
          The recipient will need to enter a dynamic pin code to get the secret.
        </UncontrolledTooltip>
      </div>
    );
  } else if (settings && settings.keys && settings.keys.length > 0) {
    settingsAux.push(
      <div>
        <div id="seckeys-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-key fa-wizard-badges mt-3"></div>
          <div>Keys</div>
        </div>
        <UncontrolledTooltip delay={0} target={`seckeys-options`} placement="top">
          The recipient will need to use a security key to get the secret.
        </UncontrolledTooltip>
      </div>
    );
  } else if (settings && settings.emailVerification && settings.emailVerification.id) {
    settingsAux.push(
      <div>
        <div id="email-id-options" className="btn-icon btn-round btn btn-xl">
          <div className="fa fa-envelope fa-wizard-badges mt-3"></div>
          <div>Email ID</div>
        </div>
        <UncontrolledTooltip delay={0} target={`email-id-options`} placement="top">
          The recipient will need verify the email.
        </UncontrolledTooltip>
      </div>
    );
  }

  const [openedCollapseOne, setopenedCollapseOne] = useState(false);

  const copyRichText = (text: String, state: any) => {
    const { revealMessage, sendMessage, purpose } = state.data;

    if(isMobileTablet()){
      navigator.clipboard.writeText(text.toString());
    } else {
      const listener = function (ev: any) {
        let copyAnchorText = purpose === 'receive'? sendMessage: revealMessage;
        ev.preventDefault();
        ev.clipboardData.setData(
          "text/html",
          '<a href="' + text + '" target="_blank">' + copyAnchorText + "</a>"
        );
        ev.clipboardData.setData("text/plain", text);
      };
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    }
    
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              {history.location.state.data.purpose == 'receive' ?
              <>
                <CardTitle tag="h4">
                  Your envelope is ready to be SharePassed!{" "}
                  <small className="text-primary clickable" id="whatToDoTooltip">
                    (what to do now?)
                  </small>
                </CardTitle>

                <UncontrolledTooltip
                  delay={0}
                  target="whatToDoTooltip"
                  placement="right"
                >
                  The link is ready to be shared with the recipient by email, SMS,
                  instant chat, or any similar channel. Copy, paste, and SharePass
                  it!
                </UncontrolledTooltip>
              </>
              :
              <>
                <CardTitle tag="h4">
                  Your secret is ready to be SharePassed!{" "}
                  <small className="text-primary clickable" id="whatToDoTooltip">
                    (what to do now?)
                  </small>
                </CardTitle>

                <UncontrolledTooltip
                  delay={0}
                  target="whatToDoTooltip"
                  placement="right"
                >
                  The link is ready to be shared with the recipient by email, SMS,
                  instant chat, or any similar channel. Copy, paste, and SharePass
                  it!
                </UncontrolledTooltip>
              </>
              }
            </CardHeader>
            <CardBody>
              <FormGroup className="flex">
                <Input
                  type="text"
                  className="form-control clickable"
                  readOnly={true}
                  value={url}
                  onClick={() => {
                    setCopied();
                    //navigator.clipboard.writeText(url);
                    copyRichText(url, history.location.state);
                  }}
                />
                <img
                  alt="copy-icon"
                  className={`btn-lock ${!copy ? "cursor-p" : ""}`}
                  onClick={() => {
                    setCopied();
                    //navigator.clipboard.writeText(url);
                    copyRichText(url, history.location.state);
                  }}
                  src={copy ? clipboardCheck : clipboard}
                  width={40}
                />
              </FormGroup>
              {copy && false && (
                <Button
                  className="btn btn-sm btn-random bottom10"
                  color="primary"
                  disabled
                >
                  <img alt="copied-icon" src={clipboardCheckW} width="20" />{" "}
                  Copied!
                </Button>
              )}
              <Alert className="d-none" color="success">
                <span>
                  {expireIn.title}
                  <b>{expireIn.info}</b>
                </span>
              </Alert>
              <div className="text-center">
                <Button color="primary" onClick={goToNewSecret}>
                  <FontAwesomeIcon
                    className="right5"
                    icon={faRedoAlt}
                    color="#fff"
                  />{" "}
                  REPEAT IT!
                </Button>
              </div>

              <div className="form-formik mt-3">
                <div
                  aria-multiselectable={true}
                  className="card-collapse"
                  id="accordion-summary"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader role="tab">
                      <a
                        aria-expanded={openedCollapseOne}
                        href="#pabo"
                        data-parent="#accordion-summary"
                        data-toggle="collapse"
                        onClick={(e) => {
                          e.preventDefault();
                          setopenedCollapseOne(!openedCollapseOne);
                        }}
                      >
                        Secret summary
                        <i className="tim-icons icon-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={openedCollapseOne}
                      className="collapse-settings"
                    >
                      <CardBody>
                        <Row>
                          <Col lg="2" md="12"></Col>
                          <Col lg="8" md="12">
                            <div className="tlh-ttl-zone text-center">
                              <div>
                                <h4>Timeline</h4>
                              </div>
                              <small>
                                {settings &&
                                settings.ttl &&
                                (settings.ttl.relative ||
                                  settings.ttl.absolute) ? (
                                  <>
                                    Time to live:{" "}
                                    {history.location &&
                                    history.location.state &&
                                    history.location.state.data
                                      ? history.location.state.data
                                          .expiryRelative
                                      : ""}
                                  </>
                                ) : (
                                  "Time to live: Forever"
                                )}
                              </small>
                            </div>
                            <div className="tlh-wrapper">
                              <div className="tlh-timeline-horizontal">
                                <div className="tlh-event">
                                  <span className="tlh-arrow-back"></span>
                                </div>
                                <div className="tlh-event tlh-filled">
                                  <span
                                    id="availability-options"
                                    className="tlh-dot"
                                  ></span>
                                  <span className="tlh-stage-name">Start</span>

                                  <UncontrolledTooltip
                                    delay={0}
                                    target={`availability-options`}
                                    placement="top"
                                  >
                                    Available from{" "}
                                    <b>
                                      {history.location &&
                                      history.location.state &&
                                      history.location.state.data
                                        ? history.location.state.data
                                            .availableFromRelative
                                        : ""}
                                    </b>
                                    <br />(
                                    {history.location &&
                                    history.location.state &&
                                    history.location.state.data
                                      ? moment(
                                          history.location.state.data
                                            .availableFrom * 1000
                                        )
                                          .format("ddd D MMM YYYY HH:mm A")
                                          .toString()
                                      : ""}
                                    )
                                  </UncontrolledTooltip>
                                </div>
                                {settings &&
                                settings.ttl &&
                                (settings.ttl.relative ||
                                  settings.ttl.absolute) ? (
                                  <>
                                    <div className="tlh-event tlh-filled-transition"></div>
                                    <div className="tlh-event tlh-filled-end">
                                      <span
                                        id="ttl-options"
                                        className="tlh-dot"
                                      ></span>

                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`ttl-options`}
                                        placement="top"
                                      >
                                        Expires in{" "}
                                        <b>
                                          {history.location &&
                                          history.location.state &&
                                          history.location.state.data
                                            ? history.location.state.data
                                                .expiryRelative
                                            : ""}
                                        </b>
                                        <br />(
                                        {history.location &&
                                        history.location.state &&
                                        history.location.state.data
                                          ? moment(
                                              history.location.state.data
                                                .expiry * 1000
                                            )
                                              .format("ddd D MMM YYYY HH:mm A")
                                              .toString()
                                          : ""}
                                        )
                                      </UncontrolledTooltip>
                                    </div>
                                    <div className="tlh-event">
                                      <span className="tlh-arrow-forward"></span>
                                      <span className="tlh-stage-name">
                                        Expiry
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="tlh-event tlh-filled">
                                      <span className="tlh-arrow-forward"></span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="12">
                            <div className="badges-list text-center">
                              {settingsAux.map((item, index) => (
                                <div className="badge-item d-inline-block">
                                  {item}
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
