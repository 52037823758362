/*eslint-disable*/
import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { useSortBy, useTable } from "react-table";
import listBGDark from "../../assets/img/list-bg-dark.png";
import listBGLight from "../../assets/img/list-bg-light.png";

// Our table component
const Table = ({
  columns,
  data,
  isLoading,
  lastItem,
  update,
  sortAsc,
  sortByAsc,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const history = useHistory();

  const [isScrolled, setIsScrolled] = useState(false);
  const onScroll = (e) => {
    const { path } = e;
    if (path) setIsScrolled(path[0].scrollTop > 0);
  };

  let userDetails = localStorage.getItem("user");

  let lightTheme = JSON.parse(userDetails).lightTheme;

  const goToNewSecret = function () {
    history.push("/admin/secret/new-secret");
  };

  return (
    <div className="ReactTable fixed-table-width -striped -highlight">
      <table {...getTableProps()} className="rt-table no-overflow">
        <thead className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column, key) => {
                return (
                  <th key={column.id} className="rt-th rt-resizable-header">
                    <div className="rt-resizable-header-content">
                      {column.render("Header") !== "Actions" &&
                        column.render("Header")}

                      {key === 0 && (
                        <FontAwesomeIcon
                          icon={sortAsc ? faSortAmountDown : faSortAmountUp}
                          className={`right top8 cursor-p ${
                            isLoading ? "disabled" : ""
                          }`}
                          onClick={sortByAsc}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <InfiniteScroll
          dataLength={rows.length}
          next={update}
          hasMore={lastItem}
          scrollableTarget="scrollable-div"
          onScroll={onScroll}
          initialScrollY={20}
        >
          {isScrolled && <div className="shadow-top"></div>}
          <div
            id="scrollable-div"
            className={`content-scroll ${isLoading ? "disabled" : ""}`}
          >
            <tbody {...getTableBodyProps()} className="rt-tbody">
              {rows.length ? (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="rt-tr -even" key={i}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`rt-td ${isLoading ? "disabled" : ""}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : isLoading ? (
                <tr>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                  <td className="text-center">
                    <img src={lightTheme ? listBGLight : listBGDark} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td
                    style={{ width: "100vw" }}
                    className="text-center"
                    colSpan={8}
                  >
                    <h4 className="mt-5">
                      You don't have any secrets. Create your first one{" "}
                      <span className="u-link-cursor" onClick={goToNewSecret}>
                        here
                      </span>
                      !
                    </h4>
                  </td>
                </tr>
              )}
            </tbody>
          </div>
          {isScrolled && <div className="shadow-bottom"></div>}
        </InfiniteScroll>
      </table>
      {/* <div className="pagination-bottom">asasa</div> */}
    </div>
  );
};

export default Table;
