/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "../../views/Dashboard.js";
import ComingSoon from "../../views/adminportal/ComingSoon.tsx";
import { NewSecurityKey as GlobalNewSecurityKey } from "../../views/adminportal/security-key/NewSecurityKey.tsx";
import { SecurityKeyList as GlobalSecurityKeysList } from "../../views/adminportal/security-key/SecurityKeyList.tsx";
import { EditTemplate as GlobalEditTemplate } from "../../views/adminportal/template/EditTemplate.tsx";
import { NewTemplate as GlobalNewTemplate } from "../../views/adminportal/template/NewTemplate.tsx";
import { TemplateList as GlobalTemplateList } from "../../views/adminportal/template/TemplateList.tsx";
import { UsersList } from "../../views/adminportal/users/UsersList";
import EnrollSecurityKey from "../../views/pages/EnrollSecurityKey.js";
import EnvelopeComplete from "../../views/pages/EnvelopeComplete.js";
import { EnvelopeReceive } from "../../views/pages/EnvelopeReceive";
import Login from "../../views/pages/Login.js";
import SSO from "../../views/pages/SSO.tsx";
import { SubscriptionPlans } from "../../views/plans/SubscriptionPlans.tsx";
import Profile from "../../views/profile/Profile.tsx";
import NewSecret from "../../views/secret/NewSecret.tsx";
import { SecretError } from "../../views/secret/SecretError.tsx";
import { SecretList } from "../../views/secret/SecretList.tsx";
import { SecretSuccess } from "../../views/secret/SecretSuccess.tsx";
import NewSecurityKey from "../../views/security-key/NewSecurityKey.tsx";
import { SecurityKeyList } from "../../views/security-key/SecurityKeyList.tsx";
import { EditTemplate } from "../../views/template/EditTemplate.tsx";
import { NewTemplate } from "../../views/template/NewTemplate.tsx";
import { TemplateList } from "../../views/template/TemplateList.tsx";

const routes = [
  /**
   * BEGIN: Admin routes
   */
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02",
    component: UsersList,
    layout: "/adminportal",
    superAdmin: true,
  },
  {
    path: "/template/list",
    name: "Templates",
    icon: "tim-icons icon-paper",
    component: GlobalTemplateList,
    layout: "/adminportal",
    superAdmin: true,
  },
  {
    path: "/template/new-template",
    name: "New template",
    mini: "NT",
    component: GlobalNewTemplate,
    layout: "/adminportal",
    redirect: true,
  },
  {
    path: "/template/edit",
    name: "Edit template",
    mini: "ET",
    component: GlobalEditTemplate,
    layout: "/adminportal",
    redirect: true,
  },
  {
    path: "/security-key/list",
    name: "Security keys",
    icon: "tim-icons icon-key-25",
    component: GlobalSecurityKeysList,
    layout: "/adminportal",
    superAdmin: true,
  },
  {
    path: "/security-key/new-security-key",
    name: "New security key",
    mini: "NS",
    component: GlobalNewSecurityKey,
    layout: "/adminportal",
    redirect: true,
  },
  {
    path: "/policies",
    name: "Policies",
    icon: "tim-icons icon-notes",
    component: ComingSoon,
    layout: "/adminportal",
    superAdmin: true,
  },
  /**
   * END: Admin routes
   */
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    purpose: 0,
  },
  {
    collapse: true,
    name: "Pages",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    redirect: true,
    views: [
      {
        path: "/enroll-security-key",
        name: "Enroll security key",
        component: EnrollSecurityKey,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/sso",
        name: "SSO",
        component: SSO,
        layout: "/auth",
      },
      {
        path: "/envelope",
        name: "Complete your envelope",
        component: EnvelopeComplete,
        layout: "/auth",
      },
      {
        path: "/envelope/receive",
        name: "Decrypt your envelope",
        component: EnvelopeReceive,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "My secrets",
    icon: "tim-icons icon-lock-circle",
    state: "componentsCollapse",
    purpose: 0,
    views: [
      {
        path: "/secret/new-secret",
        name: "New secret",
        mini: "NS",
        component: NewSecret,
        layout: "/admin",
        purpose: 0,
      },
      {
        path: "/secret/list",
        name: "Secret list",
        mini: "SL",
        component: SecretList,
        layout: "/admin",
        purpose: 0,
      },
    ],
  },
  {
    collapse: true,
    name: "Security Keys",
    icon: "tim-icons icon-key-25",
    state: "securityKeysCollapse",
    purpose: 0,
    views: [
      {
        path: "/security-key/new-security-key",
        name: "New Security key",
        mini: "NK",
        component: NewSecurityKey,
        layout: "/admin",
        purpose: 0,
      },
      {
        path: "/security-key/list",
        name: "Security key list",
        mini: "KL",
        component: SecurityKeyList,
        layout: "/admin",
        purpose: 0,
      },
      {
        path: "/security-key/edit",
        name: "Edit security key",
        mini: "EK",
        component: EditTemplate,
        layout: "/admin",
        redirect: true,
        purpose: 0,
      },
    ],
  },
  {
    collapse: true,
    name: "My templates",
    icon: "tim-icons icon-paper",
    state: "formsCollapse",
    purpose: 0,
    views: [
      {
        path: "/template/new-template",
        name: "New template",
        mini: "NT",
        component: NewTemplate,
        layout: "/admin",
        purpose: 0,
      },
      {
        path: "/template/list",
        name: "Template list",
        mini: "TL",
        component: TemplateList,
        layout: "/admin",
        purpose: 0,
      },
      {
        path: "/template/edit",
        name: "Edit template",
        mini: "ET",
        component: EditTemplate,
        layout: "/admin",
        redirect: true,
        purpose: 0,
      },
    ],
  },
  {
    collapse: true,
    name: "Settings",
    icon: "tim-icons icon-settings-gear-63",
    state: "tablesCollapse",
    redirect: true,
    views: [
      {
        path: "/settings/profile",
        name: "Settings",
        mini: "PR",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/settings/plans",
        name: "Plans",
        mini: "PL",
        component: SubscriptionPlans,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/secret/success",
    name: "Secret success",
    component: SecretSuccess,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/secret/error",
    name: "Secret error",
    component: SecretError,
    layout: "/admin",
    redirect: true,
  },

  /**
   * BEGIN: routes for envelope
   */
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    purpose: 1,
  },
  {
    collapse: true,
    name: "My envelopes",
    icon: "tim-icons icon-lock-circle",
    state: "componentsCollapse",
    purpose: 1,
    views: [
      {
        path: "/envelope/add",
        name: "New envelope",
        mini: "NE",
        component: NewSecret,
        layout: "/admin",
        purpose: 1,
      },
      {
        path: "/envelope/list",
        name: "Envelopes list",
        mini: "EL",
        component: SecretList,
        layout: "/admin",
        purpose: 1,
      },
    ],
  },
  {
    collapse: true,
    name: "Security Keys",
    icon: "tim-icons icon-key-25",
    state: "securityKeysCollapse",
    purpose: 1,
    views: [
      {
        path: "/security-key/new-security-key",
        name: "New Security key",
        mini: "NK",
        component: NewSecurityKey,
        layout: "/admin",
        purpose: 1,
      },
      {
        path: "/security-key/list",
        name: "Security key list",
        mini: "KL",
        component: SecurityKeyList,
        layout: "/admin",
        purpose: 1,
      },
      {
        path: "/security-key/edit",
        name: "Edit security key",
        mini: "EK",
        component: EditTemplate,
        layout: "/admin",
        redirect: true,
        purpose: 1,
      },
    ],
  },
  {
    collapse: true,
    name: "My templates",
    icon: "tim-icons icon-paper",
    state: "formsCollapse",
    purpose: 1,
    views: [
      {
        path: "/template/new-template",
        name: "New template",
        mini: "NT",
        component: NewTemplate,
        layout: "/admin",
        purpose: 1,
      },
      {
        path: "/template/list",
        name: "Template list",
        mini: "TL",
        component: TemplateList,
        layout: "/admin",
        purpose: 1,
      },
      {
        path: "/template/edit",
        name: "Edit template",
        mini: "ET",
        component: EditTemplate,
        layout: "/admin",
        redirect: true,
        purpose: 1,
      },
    ],
  },
  /**
   * END: routes for envelope
   */
];

export default routes;
